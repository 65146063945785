import { default as _91orderid_93TWYnuZYfkyMeta } from "/vercel/path0/pages/[locale]/account/dashboard/orders/[orderid].vue?macro=true";
import { default as indexx6FCYQssO9Meta } from "/vercel/path0/pages/[locale]/account/dashboard/returns/index.vue?macro=true";
import { default as dashboard7p65x1DU0OMeta } from "/vercel/path0/pages/[locale]/account/dashboard.vue?macro=true";
import { default as loginXgf4bfo4HpMeta } from "/vercel/path0/pages/[locale]/account/login.vue?macro=true";
import { default as careers8LVliREKQUMeta } from "/vercel/path0/pages/[locale]/careers.vue?macro=true";
import { default as prescriptionHtDrwzlTaxMeta } from "/vercel/path0/pages/[locale]/checkout/prescription.vue?macro=true";
import { default as _91article_93DXppt6cJfeMeta } from "/vercel/path0/pages/[locale]/explore/[category]/[article].vue?macro=true";
import { default as index6qPaNkZD7LMeta } from "/vercel/path0/pages/[locale]/explore/[category]/index.vue?macro=true";
import { default as indexJAZPEVGjNpMeta } from "/vercel/path0/pages/[locale]/explore/index.vue?macro=true";
import { default as _91_46_46_46filter_45page_935BTtp9ZRnyMeta } from "/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue?macro=true";
import { default as _91_46_46_46generic_45landing_45page_933mULUkjA31Meta } from "/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue?macro=true";
import { default as giftsujjgOGbFC8Meta } from "/vercel/path0/pages/[locale]/gifts.vue?macro=true";
import { default as indexy0XS6zLb0pMeta } from "/vercel/path0/pages/[locale]/index.vue?macro=true";
import { default as _91slug_93dsFj9paSqbMeta } from "/vercel/path0/pages/[locale]/info/[slug].vue?macro=true";
import { default as mission13GFiC05r3Meta } from "/vercel/path0/pages/[locale]/mission.vue?macro=true";
import { default as _91product_93pKHp3s3SyYMeta } from "/vercel/path0/pages/[locale]/owners/[product].vue?macro=true";
import { default as indexlv1vQdwSSyMeta } from "/vercel/path0/pages/[locale]/owners/index.vue?macro=true";
import { default as ownersJ0vJEOiiEjMeta } from "/vercel/path0/pages/[locale]/owners.vue?macro=true";
import { default as _91slug_93PiN59E4KP2Meta } from "/vercel/path0/pages/[locale]/register/[slug].vue?macro=true";
import { default as returns_46clientEm7gL2rpDiMeta } from "/vercel/path0/pages/[locale]/returns.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/vercel/path0/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as reviewsrEPIUxVTPxMeta } from "/vercel/path0/pages/[locale]/reviews.vue?macro=true";
import { default as indexVFGh14RhQYMeta } from "/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue?macro=true";
import { default as indexylUyG9oox5Meta } from "/vercel/path0/pages/[locale]/shop/[category]/index.vue?macro=true";
import { default as instagramdJmVMJgWaqMeta } from "/vercel/path0/pages/[locale]/shop/instagram.vue?macro=true";
import { default as redirectXANEz9XQ3aMeta } from "/vercel/path0/pages/[locale]/shop/redirect.vue?macro=true";
import { default as sitemapGsJOLVDcgGMeta } from "/vercel/path0/pages/[locale]/sitemap.vue?macro=true";
import { default as indexte6WvDCQ1xMeta } from "/vercel/path0/pages/[locale]/support/[category]/[article]/index.vue?macro=true";
import { default as _91category_93aslyJ5olVcMeta } from "/vercel/path0/pages/[locale]/support/[category].vue?macro=true";
import { default as index3MomKvaJbLMeta } from "/vercel/path0/pages/[locale]/support/contact-us/index.vue?macro=true";
import { default as sentn6NcvVERYFMeta } from "/vercel/path0/pages/[locale]/support/contact-us/sent.vue?macro=true";
import { default as supportLTBaAnrQIfMeta } from "/vercel/path0/pages/[locale]/support.vue?macro=true";
import { default as _91_46_46_46page_93zbGHxdQkeFMeta } from "/vercel/path0/pages/[locale]/test/[...page].vue?macro=true";
import { default as _91slug_93nA585D1AOqMeta } from "/vercel/path0/pages/[locale]/typeform/[slug].vue?macro=true";
import { default as welcomedybhWXmqThMeta } from "/vercel/path0/pages/[locale]/welcome.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "locale-account-dashboard",
    path: "/:locale()/account/dashboard",
    component: () => import("/vercel/path0/pages/[locale]/account/dashboard.vue"),
    children: [
  {
    name: "locale-account-dashboard-orders-orderid",
    path: "orders/:orderid()",
    component: () => import("/vercel/path0/pages/[locale]/account/dashboard/orders/[orderid].vue")
  },
  {
    name: "locale-account-dashboard-returns",
    path: "returns",
    component: () => import("/vercel/path0/pages/[locale]/account/dashboard/returns/index.vue")
  }
]
  },
  {
    name: "locale-account-login",
    path: "/:locale()/account/login",
    component: () => import("/vercel/path0/pages/[locale]/account/login.vue")
  },
  {
    name: "locale-careers",
    path: "/:locale()/careers",
    meta: careers8LVliREKQUMeta || {},
    component: () => import("/vercel/path0/pages/[locale]/careers.vue")
  },
  {
    name: "locale-checkout-prescription",
    path: "/:locale()/checkout/prescription",
    meta: prescriptionHtDrwzlTaxMeta || {},
    component: () => import("/vercel/path0/pages/[locale]/checkout/prescription.vue")
  },
  {
    name: "locale-explore-category-article",
    path: "/:locale()/explore/:category()/:article()",
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/[article].vue")
  },
  {
    name: "locale-explore-category",
    path: "/:locale()/explore/:category()",
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue")
  },
  {
    name: "ExploreListing",
    path: "/:locale()/explore",
    meta: indexJAZPEVGjNpMeta || {},
    component: () => import("/vercel/path0/pages/[locale]/explore/index.vue")
  },
  {
    name: "locale-filter-page-filterpage",
    path: "/:locale()/filter-page/:filterpage(.*)*",
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "locale-generic-landing-page-genericlandingpage",
    path: "/:locale()/generic-landing-page/:genericlandingpage(.*)*",
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "locale-gifts",
    path: "/:locale()/gifts",
    meta: giftsujjgOGbFC8Meta || {},
    component: () => import("/vercel/path0/pages/[locale]/gifts.vue")
  },
  {
    name: "Home",
    path: "/:locale()",
    meta: indexy0XS6zLb0pMeta || {},
    component: () => import("/vercel/path0/pages/[locale]/index.vue")
  },
  {
    name: "locale-info-slug",
    path: "/:locale()/info/:slug()",
    component: () => import("/vercel/path0/pages/[locale]/info/[slug].vue")
  },
  {
    name: "locale-mission",
    path: "/:locale()/mission",
    meta: mission13GFiC05r3Meta || {},
    component: () => import("/vercel/path0/pages/[locale]/mission.vue")
  },
  {
    name: ownersJ0vJEOiiEjMeta?.name,
    path: "/:locale()/owners",
    component: () => import("/vercel/path0/pages/[locale]/owners.vue"),
    children: [
  {
    name: "locale-owners-product",
    path: ":product()",
    component: () => import("/vercel/path0/pages/[locale]/owners/[product].vue")
  },
  {
    name: "locale-owners",
    path: "",
    component: () => import("/vercel/path0/pages/[locale]/owners/index.vue")
  }
]
  },
  {
    name: "locale-register-slug",
    path: "/:locale()/register/:slug()",
    meta: _91slug_93PiN59E4KP2Meta || {},
    component: () => import("/vercel/path0/pages/[locale]/register/[slug].vue")
  },
  {
    name: "locale-returns",
    path: "/:locale()/returns",
    component: () => createClientPage(() => import("/vercel/path0/pages/[locale]/returns.client.vue"))
  },
  {
    name: "locale-reviews",
    path: "/:locale()/reviews",
    meta: reviewsrEPIUxVTPxMeta || {},
    component: () => import("/vercel/path0/pages/[locale]/reviews.vue")
  },
  {
    name: "locale-shop-category-product",
    path: "/:locale()/shop/:category()/:product()",
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "locale-shop-category",
    path: "/:locale()/shop/:category()",
    meta: indexylUyG9oox5Meta || {},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/index.vue")
  },
  {
    name: "locale-shop-instagram",
    path: "/:locale()/shop/instagram",
    component: () => import("/vercel/path0/pages/[locale]/shop/instagram.vue")
  },
  {
    name: "locale-shop-redirect",
    path: "/:locale()/shop/redirect",
    meta: redirectXANEz9XQ3aMeta || {},
    component: () => import("/vercel/path0/pages/[locale]/shop/redirect.vue")
  },
  {
    name: "locale-sitemap",
    path: "/:locale()/sitemap",
    component: () => import("/vercel/path0/pages/[locale]/sitemap.vue")
  },
  {
    name: "SupportPage",
    path: "/:locale()/support",
    meta: supportLTBaAnrQIfMeta || {},
    component: () => import("/vercel/path0/pages/[locale]/support.vue"),
    children: [
  {
    name: "locale-support-category",
    path: ":category()",
    meta: _91category_93aslyJ5olVcMeta || {},
    component: () => import("/vercel/path0/pages/[locale]/support/[category].vue"),
    children: [
  {
    name: "locale-support-category-article",
    path: ":article()",
    meta: indexte6WvDCQ1xMeta || {},
    component: () => import("/vercel/path0/pages/[locale]/support/[category]/[article]/index.vue")
  }
]
  },
  {
    name: "locale-support-contact-us",
    path: "contact-us",
    meta: index3MomKvaJbLMeta || {},
    component: () => import("/vercel/path0/pages/[locale]/support/contact-us/index.vue")
  },
  {
    name: "locale-support-contact-us-sent",
    path: "contact-us/sent",
    component: () => import("/vercel/path0/pages/[locale]/support/contact-us/sent.vue")
  }
]
  },
  {
    name: "locale-test-page",
    path: "/:locale()/test/:page(.*)*",
    meta: _91_46_46_46page_93zbGHxdQkeFMeta || {},
    component: () => import("/vercel/path0/pages/[locale]/test/[...page].vue")
  },
  {
    name: "locale-typeform-slug",
    path: "/:locale()/typeform/:slug()",
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue")
  },
  {
    name: "WelcomePage",
    path: "/:locale()/welcome",
    meta: welcomedybhWXmqThMeta || {},
    component: () => import("/vercel/path0/pages/[locale]/welcome.vue")
  },
  {
    name: "fp-bestselling-sunglasses-bestselling-sunglasses",
    path: "/:locale()/bestselling-sunglasses",
    meta: {"id":"PLJ7f9L9SmKRq9r_ed87AQ","slug":"bestselling-sunglasses","index":true,"title":"Bestselling Sunglasses  | SunGod. See Better.","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/bestselling-sunglasses","slugLocaleMapping":{"bestselling-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__mclaren__all-shop__mclaren__all",
    path: "/:locale()/shop/mclaren/all",
    meta: {"id":"bBBdmTiPSGCVDSvICLg09g","slug":"shop__mclaren__all","index":true,"title":"McLaren Sunglasses: 2025 Collection","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/mclaren/all","slugLocaleMapping":{"shop__mclaren__all":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-road-cycling-sunglasses-road-cycling-sunglasses",
    path: "/:locale()/road-cycling-sunglasses",
    meta: {"id":"SJzlkf_cSGiMjFYXokLFyg","slug":"road-cycling-sunglasses","index":true,"title":"Road Cycling Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/road-cycling-sunglasses","slugLocaleMapping":{"road-cycling-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-cycling-sunglasses-cycling-sunglasses",
    path: "/:locale()/cycling-sunglasses",
    meta: {"id":"K1bxCC1kQG-C1eohDRpX-w","slug":"cycling-sunglasses","index":true,"title":"Cycling Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/cycling-sunglasses","slugLocaleMapping":{"cycling-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-mens-running-sunglasses-mens-running-sunglasses",
    path: "/:locale()/mens-running-sunglasses",
    meta: {"id":"RbqWEON0QEaCCjr1B8c7FQ","slug":"mens-running-sunglasses","index":true,"title":"Road Running Sunglasses. ","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/mens-running-sunglasses","slugLocaleMapping":{"mens-running-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-trail-running-sunglasses-trail-running-sunglasses",
    path: "/:locale()/trail-running-sunglasses",
    meta: {"id":"S1QvilrMRAiPB7QAXswakA","slug":"trail-running-sunglasses","index":true,"title":"Trail Running Sunglasses | SunGod. See Better.","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/trail-running-sunglasses","slugLocaleMapping":{"trail-running-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-womens-running-sunglasses-womens-running-sunglasses",
    path: "/:locale()/womens-running-sunglasses",
    meta: {"id":"YI15u15aTA6Zg-PgImfAiw","slug":"womens-running-sunglasses","index":true,"title":"Women's Running Sunglasses | 100% UV Protected","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/womens-running-sunglasses","slugLocaleMapping":{"womens-running-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-running-sunglasses-running-sunglasses",
    path: "/:locale()/running-sunglasses",
    meta: {"id":"D1Ac0e6CSd2_q8hPstSr2A","slug":"running-sunglasses","index":true,"title":"Running Sunglasses | 100% UV Protection","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/running-sunglasses","slugLocaleMapping":{"running-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__limited-edition-shop__limited-edition",
    path: "/:locale()/shop/limited-edition",
    meta: {"id":"QXML7NAFRSOi7bBqJQwkHQ","slug":"shop__limited-edition","index":true,"title":"Limited Edition Sunglasses & Ski Goggles ","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/limited-edition","slugLocaleMapping":{"shop__limited-edition":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-williams-sunglasses-williams-sunglasses",
    path: "/:locale()/williams-sunglasses",
    meta: {"id":"VxA8Ib0vRkKDwLEEbKHorg","slug":"williams-sunglasses","index":true,"title":"Williams F1 Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/williams-sunglasses","slugLocaleMapping":{"williams-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__custom-shop__custom",
    path: "/:locale()/shop/custom",
    meta: {"id":"epHdwzXCQ0yOZjwCBfC0hw","slug":"shop__custom","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/custom","slugLocaleMapping":{"shop__custom":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__custom-lifestyle-shop__custom-lifestyle",
    path: "/:locale()/shop/custom-lifestyle",
    meta: {"id":"Yl9ETsORTE2aqRLyYO60Yw","slug":"shop__custom-lifestyle","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/custom-lifestyle","slugLocaleMapping":{"shop__custom-lifestyle":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-protect-our-winters-uk-protect-our-winters-uk",
    path: "/:locale()/protect-our-winters-uk",
    meta: {"id":"cWFe4wbeQ-ygxW0ZKNGiOg","slug":"protect-our-winters-uk","index":true,"title":"Ski Goggles from Protect Our Winters UK","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/protect-our-winters-uk","slugLocaleMapping":{"protect-our-winters-uk":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__goggles__bestseller-goggles-shop__goggles__bestseller-goggles",
    path: "/:locale()/shop/goggles/bestseller-goggles",
    meta: {"id":"IY-gmOusS4KFtLTSKh7aSw","slug":"shop__goggles__bestseller-goggles","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/goggles/bestseller-goggles","slugLocaleMapping":{"shop__goggles__bestseller-goggles":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-1-for-the-planet-1-for-the-planet",
    path: "/:locale()/1-for-the-planet",
    meta: {"id":"GCs26U0EQI69bRsHkjPzVg","slug":"1-for-the-planet","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/1-for-the-planet","slugLocaleMapping":{"1-for-the-planet":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__pace__performance-limited-edition-shop__pace__performance-limited-edition",
    path: "/:locale()/shop/pace/performance-limited-edition",
    meta: {"id":"bPceoyyVRMybizU_0znr2A","slug":"shop__pace__performance-limited-edition","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/pace/performance-limited-edition","slugLocaleMapping":{"shop__pace__performance-limited-edition":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-ski-goggle-protectors-ski-goggle-protectors",
    path: "/:locale()/ski-goggle-protectors",
    meta: {"id":"GsAHxC3vSde730dRHlb9bw","slug":"ski-goggle-protectors","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/ski-goggle-protectors","slugLocaleMapping":{"ski-goggle-protectors":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__katie-ormerod__all-shop__katie-ormerod__all",
    path: "/:locale()/shop/katie-ormerod/all",
    meta: {"id":"EcHgiL4LTIqiAWFzpjmnrg","slug":"shop__katie-ormerod__all","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/katie-ormerod/all","slugLocaleMapping":{"shop__katie-ormerod__all":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-snow-goggles-snow-goggles",
    path: "/:locale()/snow-goggles",
    meta: {"id":"QUC63xG1SEKiaFuY3W-ebw","slug":"snow-goggles","index":true,"title":"Snow Goggles","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/snow-goggles","slugLocaleMapping":{"snow-goggles":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__microfibres-shop__microfibres",
    path: "/:locale()/shop/microfibres",
    meta: {"id":"N4Fc5rBMTfm9sPdDNGI7ag","slug":"shop__microfibres","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/microfibres","slugLocaleMapping":{"shop__microfibres":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__cases-shop__cases",
    path: "/:locale()/shop/cases",
    meta: {"id":"INbYYSxCQuiZEhryleBW3g","slug":"shop__cases","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/cases","slugLocaleMapping":{"shop__cases":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-limited-edition-goggles-limited-edition-goggles",
    path: "/:locale()/limited-edition-goggles",
    meta: {"id":"PcmRMiTTSii3qHdiuNN1ZQ","slug":"limited-edition-goggles","index":true,"title":"Limited Edition Ski & Snowboard Goggles","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/limited-edition-goggles","slugLocaleMapping":{"limited-edition-goggles":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-lifestyle-8ko-polarised-lifestyle-8ko-polarised",
    path: "/:locale()/lifestyle-8ko-polarised",
    meta: {"id":"Sc-xOE9MQe-5vXpLLqoIjA","slug":"lifestyle-8ko-polarised","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/lifestyle-8ko-polarised","slugLocaleMapping":{"lifestyle-8ko-polarised":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__lifestyle-shop__lifestyle",
    path: "/:locale()/shop/lifestyle",
    meta: {"id":"IpiceQ9YQGqSAdSnfnN0MQ","slug":"shop__lifestyle","index":true,"title":"Everyday/Active Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/lifestyle","slugLocaleMapping":{"shop__lifestyle":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-8ko-lifestyle-sunglasses-8ko-lifestyle-sunglasses",
    path: "/:locale()/8ko-lifestyle-sunglasses",
    meta: {"id":"SLTNJ1FFTG6Tz19CnByqvg","slug":"8ko-lifestyle-sunglasses","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/8ko-lifestyle-sunglasses","slugLocaleMapping":{"8ko-lifestyle-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-8ko-lifestyle-and-performance-sunglasses-8ko-lifestyle-and-performance-sunglasses",
    path: "/:locale()/8ko-lifestyle-and-performance-sunglasses",
    meta: {"id":"MzQzRltpSOeEEPbpDp529g","slug":"8ko-lifestyle-and-performance-sunglasses","index":true,"title":"8KO® Sunglasses from SunGod","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/8ko-lifestyle-and-performance-sunglasses","slugLocaleMapping":{"8ko-lifestyle-and-performance-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-f1-sunglasses-f1-sunglasses",
    path: "/:locale()/f1-sunglasses",
    meta: {"id":"QNM-o3DWSI2BVQm_snFryg","slug":"f1-sunglasses","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/f1-sunglasses","slugLocaleMapping":{"f1-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-tennis-sunglasses-tennis-sunglasses",
    path: "/:locale()/tennis-sunglasses",
    meta: {"id":"WchOOw5uRuum00EnLxxbIw","slug":"tennis-sunglasses","index":true,"title":"Tennis Sunglasses | 100% UV Protection","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/tennis-sunglasses","slugLocaleMapping":{"tennis-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__pace__performance-bestseller-shop__pace__performance-bestseller",
    path: "/:locale()/shop/pace/performance-bestseller",
    meta: {"id":"EtHygREfT1OdKXaq3RwjkA","slug":"shop__pace__performance-bestseller","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/pace/performance-bestseller","slugLocaleMapping":{"shop__pace__performance-bestseller":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-sunglasses-for-small-faces-sunglasses-for-small-faces",
    path: "/:locale()/sunglasses-for-small-faces",
    meta: {"id":"RiNVS9jmQmi9dt_plK5yIg","slug":"sunglasses-for-small-faces","index":true,"title":"Sunglasses for Smaller Faces","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/sunglasses-for-small-faces","slugLocaleMapping":{"sunglasses-for-small-faces":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-triathlon-sunglasses-triathlon-sunglasses",
    path: "/:locale()/triathlon-sunglasses",
    meta: {"id":"bjL6STg-RMm05z4QvM8Qfw","slug":"triathlon-sunglasses","index":true,"title":"Triathlon Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/triathlon-sunglasses","slugLocaleMapping":{"triathlon-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-hiking-sunglasses-hiking-sunglasses",
    path: "/:locale()/hiking-sunglasses",
    meta: {"id":"P7m2OhBXS7ely_ievDxXTw","slug":"hiking-sunglasses","index":true,"title":"Hiking Sunglasses with 100% UV Protection","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/hiking-sunglasses","slugLocaleMapping":{"hiking-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-mountaineering-sunglasses-mountaineering-sunglasses",
    path: "/:locale()/mountaineering-sunglasses",
    meta: {"id":"adKUKEJpS5yFUloZmhrfCg","slug":"mountaineering-sunglasses","index":true,"title":"Mountaineering Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/mountaineering-sunglasses","slugLocaleMapping":{"mountaineering-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-watersports-sunglasses-watersports-sunglasses",
    path: "/:locale()/watersports-sunglasses",
    meta: {"id":"CV4M02IZQY-1AXVoZt9qPg","slug":"watersports-sunglasses","index":true,"title":"Watersports Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/watersports-sunglasses","slugLocaleMapping":{"watersports-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-softball-sunglasses-softball-sunglasses",
    path: "/:locale()/softball-sunglasses",
    meta: {"id":"RFsoXkrTQO6xRnzRmwr55A","slug":"softball-sunglasses","index":true,"title":"Softball Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/softball-sunglasses","slugLocaleMapping":{"softball-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-rowing-sunglasses-rowing-sunglasses",
    path: "/:locale()/rowing-sunglasses",
    meta: {"id":"a2mhQ7ksTxKBRX85ikymzA","slug":"rowing-sunglasses","index":true,"title":"Rowing Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/rowing-sunglasses","slugLocaleMapping":{"rowing-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-glasses-glasses",
    path: "/:locale()/glasses",
    meta: {"id":"Gb6uuf5GRj-jZm--1QB7Hw","slug":"glasses","index":true,"title":"Prescription Glasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/glasses","slugLocaleMapping":{"glasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-cricket-sunglasses-cricket-sunglasses",
    path: "/:locale()/cricket-sunglasses",
    meta: {"id":"EOYaatG3SzyUG8g6komQTA","slug":"cricket-sunglasses","index":true,"title":"Sunglasses for Cricket","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/cricket-sunglasses","slugLocaleMapping":{"cricket-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__custom-prescription-shop__custom-prescription",
    path: "/:locale()/shop/custom-prescription",
    meta: {"id":"HA-sOtn2Q8iLdKtP6IvEaw","slug":"shop__custom-prescription","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/custom-prescription","slugLocaleMapping":{"shop__custom-prescription":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-golf-sunglasses-golf-sunglasses",
    path: "/:locale()/golf-sunglasses",
    meta: {"id":"XSbrmnBVR6a2qoJXMwcqiA","slug":"golf-sunglasses","index":true,"title":"Polarised Golf Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/golf-sunglasses","slugLocaleMapping":{"golf-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__custom-performance-shop__custom-performance",
    path: "/:locale()/shop/custom-performance",
    meta: {"id":"Z-6p8eVoSICagoetCcuW9g","slug":"shop__custom-performance","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/custom-performance","slugLocaleMapping":{"shop__custom-performance":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-summer-solstice-summer-solstice",
    path: "/:locale()/summer-solstice",
    meta: {"id":"a356UUB5TNSb_yZkZCqfow","slug":"summer-solstice","index":true,"title":"SunGod Solstice Collection","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/summer-solstice","slugLocaleMapping":{"summer-solstice":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-black-friday-2024-black-friday-2024",
    path: "/:locale()/black-friday-2024",
    meta: {"id":"dnYJkXOvTzSyJ0NL8QbVwg","slug":"black-friday-2024","index":true,"title":"Black Friday Sunglasses Deals 2024: Free Pair with Any Pair","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/black-friday-2024","slugLocaleMapping":{"black-friday-2024":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-polarised-sunglasses-polarised-sunglasses",
    path: "/:locale()/polarised-sunglasses",
    meta: {"id":"E-29V6AJTNOIfkVQb4j6ew","slug":"polarised-sunglasses","index":true,"title":"Polarised Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/polarised-sunglasses","slugLocaleMapping":{"polarised-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"],"polarized-sunglasses":["en-us"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-polarised-sunglasses-polarized-sunglasses",
    path: "/:locale()/polarized-sunglasses",
    meta: {"id":"E-29V6AJTNOIfkVQb4j6ew","slug":"polarized-sunglasses","index":true,"title":"Polarised Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/polarised-sunglasses","slugLocaleMapping":{"polarised-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"],"polarized-sunglasses":["en-us"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-sailing-sunglasses-sailing-sunglasses",
    path: "/:locale()/sailing-sunglasses",
    meta: {"id":"Z_6M0BD2SSeRfWca7CCjsQ","slug":"sailing-sunglasses","index":true,"title":"Sailing Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/sailing-sunglasses","slugLocaleMapping":{"sailing-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-driving-sunglasses-driving-sunglasses",
    path: "/:locale()/driving-sunglasses",
    meta: {"id":"dnhRcmHlT7SIUMZubRhi-g","slug":"driving-sunglasses","index":true,"title":"Sunglasses for Driving ","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/driving-sunglasses","slugLocaleMapping":{"driving-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-baseball-sunglasses-baseball-sunglasses",
    path: "/:locale()/baseball-sunglasses",
    meta: {"id":"BBDnC-wDQ0iDJmGKlNrGSA","slug":"baseball-sunglasses","index":true,"title":"Baseball Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/baseball-sunglasses","slugLocaleMapping":{"baseball-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__8ko-snow-goggles-shop__8ko-snow-goggles",
    path: "/:locale()/shop/8ko-snow-goggles",
    meta: {"id":"LOli8PoKRP2JVx61S1Xqwg","slug":"shop__8ko-snow-goggles","index":true,"title":"8KO®snow Goggles","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/8ko-snow-goggles","slugLocaleMapping":{"shop__8ko-snow-goggles":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__all-shop__all",
    path: "/:locale()/shop/all",
    meta: {"id":"MipZrn63QvunjF0TkbwwSQ","slug":"shop__all","index":true,"title":"All Sunglasses & Goggles","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/all","slugLocaleMapping":{"shop__all":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-road-running-sunglasses-road-running-sunglasses",
    path: "/:locale()/road-running-sunglasses",
    meta: {"id":"Vg9BwmOqTUiHGf0PTbth8Q","slug":"road-running-sunglasses","index":true,"title":"Road Running Sunglasses.  | SunGod. See Better.","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/road-running-sunglasses","slugLocaleMapping":{"road-running-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-prescription-glasses-and-sunglasses-prescription-glasses-and-sunglasses",
    path: "/:locale()/prescription-glasses-and-sunglasses",
    meta: {"id":"Sgv9JT7VRaKOnoLE1uf23A","slug":"prescription-glasses-and-sunglasses","index":true,"title":"Prescription Sunglasses & Glasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/prescription-glasses-and-sunglasses","slugLocaleMapping":{"prescription-glasses-and-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-mountain-biking-sunglasses-and-goggles-mountain-biking-sunglasses-and-goggles",
    path: "/:locale()/mountain-biking-sunglasses-and-goggles",
    meta: {"id":"KTeoDkpQTtWCbACGhEEE9g","slug":"mountain-biking-sunglasses-and-goggles","index":true,"title":"Sunglasses & Goggles for Mountain Biking","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/mountain-biking-sunglasses-and-goggles","slugLocaleMapping":{"mountain-biking-sunglasses-and-goggles":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-1-percent-for-the-planet-goggles-1-percent-for-the-planet-goggles",
    path: "/:locale()/1-percent-for-the-planet-goggles",
    meta: {"id":"Z8p349iORpGhFNqzd873FA","slug":"1-percent-for-the-planet-goggles","index":true,"title":"1% for the Planet Goggles | SunGod. See Better.","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/1-percent-for-the-planet-goggles","slugLocaleMapping":{"1-percent-for-the-planet-goggles":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__ineos-grenadiers__all-shop__ineos-grenadiers__all",
    path: "/:locale()/shop/ineos-grenadiers/all",
    meta: {"id":"Hgg7SMWQRLS036m9fwjEJw","slug":"shop__ineos-grenadiers__all","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/ineos-grenadiers/all","slugLocaleMapping":{"shop__ineos-grenadiers__all":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-sungod-x-new-era-sungod-x-new-era",
    path: "/:locale()/sungod-x-new-era",
    meta: {"id":"DhogMTrtT8-d2Ax6mFkKTA","slug":"sungod-x-new-era","index":true,"title":"SunGod x New Era: Limited Edition Headwear Collection","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/sungod-x-new-era","slugLocaleMapping":{"sungod-x-new-era":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-latest-launches-latest-launches",
    path: "/:locale()/latest-launches",
    meta: {"id":"XuM9ad_bQtONI7InLQQdzA","slug":"latest-launches","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/latest-launches","slugLocaleMapping":{"latest-launches":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-fishing-sunglasses-fishing-sunglasses",
    path: "/:locale()/fishing-sunglasses",
    meta: {"id":"VsBWmMr0R4GcDmI8DIpRkQ","slug":"fishing-sunglasses","index":true,"title":"Fishing Sunglasses with Polarised Lenses ","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/fishing-sunglasses","slugLocaleMapping":{"fishing-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-everyday-sunglasses-everyday-sunglasses",
    path: "/:locale()/everyday-sunglasses",
    meta: {"id":"cUBx6fiRTX-Kg_4XuKuaLw","slug":"everyday-sunglasses","index":true,"title":"Everyday Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/everyday-sunglasses","slugLocaleMapping":{"everyday-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__prescription-sunglasses-shop__prescription-sunglasses",
    path: "/:locale()/shop/prescription-sunglasses",
    meta: {"id":"Bw3ihGHRR5O2JEizG2kv1Q","slug":"shop__prescription-sunglasses","index":true,"title":"Prescription Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/prescription-sunglasses","slugLocaleMapping":{"shop__prescription-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__pace__womens-shop__pace__womens",
    path: "/:locale()/shop/pace/womens",
    meta: {"id":"Yh7VGsjuT3KfNEpKs9wX3g","slug":"shop__pace__womens","index":true,"title":"Women's Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/pace/womens","slugLocaleMapping":{"shop__pace__womens":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"],"shop__pace__women":["en-us"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__pace__womens-shop__pace__women",
    path: "/:locale()/shop/pace/women",
    meta: {"id":"Yh7VGsjuT3KfNEpKs9wX3g","slug":"shop__pace__women","index":true,"title":"Women's Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/pace/womens","slugLocaleMapping":{"shop__pace__womens":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"],"shop__pace__women":["en-us"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__pace-shop__pace",
    path: "/:locale()/shop/pace",
    meta: {"id":"b36WQ4wMSMObwkHS8JcPMQ","slug":"shop__pace","index":true,"title":"Performance Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/pace","slugLocaleMapping":{"shop__pace":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-mens-sunglasses-mens-sunglasses",
    path: "/:locale()/mens-sunglasses",
    meta: {"id":"NmMdtJmYTM-dcz68Dtt6HQ","slug":"mens-sunglasses","index":true,"title":"Men's Sunglasses ","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/mens-sunglasses","slugLocaleMapping":{"mens-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-womens-sunglasses-womens-sunglasses",
    path: "/:locale()/womens-sunglasses",
    meta: {"id":"FPPK7GyYTLqA9cDAr4ScXg","slug":"womens-sunglasses","index":true,"title":"Women's Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/womens-sunglasses","slugLocaleMapping":{"womens-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-snowboard-goggles-snowboard-goggles",
    path: "/:locale()/snowboard-goggles",
    meta: {"id":"Lw_SAQnQQnKHJvZ9kJXbJA","slug":"snowboard-goggles","index":true,"title":"Athlete-Tested Goggles for Snowboarders","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/snowboard-goggles","slugLocaleMapping":{"snowboard-goggles":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__goggles-shop__goggles",
    path: "/:locale()/shop/goggles",
    meta: {"id":"Lo6uf7h6R2CwZuFCQBP1Jw","slug":"shop__goggles","index":true,"title":"Ski & Snowboard Goggles","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/goggles","slugLocaleMapping":{"shop__goggles":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-sports-sunglasses-sports-sunglasses",
    path: "/:locale()/sports-sunglasses",
    meta: {"id":"YTa2DaNtQHG0g4DPbC4TPA","slug":"sports-sunglasses","index":true,"title":"Sports Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/sports-sunglasses","slugLocaleMapping":{"sports-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-custom-goggles-custom-goggles",
    path: "/:locale()/custom-goggles",
    meta: {"id":"IdpBrCRpSlGGi1Agw03jSA","slug":"custom-goggles","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/custom-goggles","slugLocaleMapping":{"custom-goggles":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-ski-goggles-ski-goggles",
    path: "/:locale()/ski-goggles",
    meta: {"id":"STKrgISTSh-r7ilfqFcArg","slug":"ski-goggles","index":true,"title":"Ski Goggles with 100% UV Protection","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/ski-goggles","slugLocaleMapping":{"ski-goggles":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__accessories-shop__accessories",
    path: "/:locale()/shop/accessories",
    meta: {"id":"T1yL97j9RnusiDvJvqOX3g","slug":"shop__accessories","index":true,"extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/accessories","slugLocaleMapping":{"shop__accessories":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-ski-sunglasses-ski-sunglasses",
    path: "/:locale()/ski-sunglasses",
    meta: {"id":"AthaT7h7QBSezTwG7G_teg","slug":"ski-sunglasses","index":true,"title":"Ski Sunglasses","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/ski-sunglasses","slugLocaleMapping":{"ski-sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__sunglasses-shop__sunglasses",
    path: "/:locale()/shop/sunglasses",
    meta: {"id":"OoMqSBLQT-2p2stB20BuFw","slug":"shop__sunglasses","index":true,"title":"All Sunglasses: Award-Winning Eyewear","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/sunglasses","slugLocaleMapping":{"shop__sunglasses":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "fp-shop__pace__mens-shop__pace__mens",
    path: "/:locale()/shop/pace/mens",
    meta: {"id":"C0icLGmrRRWtVrLPSJT4lA","slug":"shop__pace__mens","index":true,"title":"Men's Performance Sunglasses ","extendedPage":true,"pageType":"Filter PLP","unlocalisedPath":"/shop/pace/mens","slugLocaleMapping":{"shop__pace__mens":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/filter-page/[...filter-page].vue")
  },
  {
    name: "glp-covent-garden-store-covent-garden-store",
    path: "/:locale()/covent-garden-store",
    meta: {"id":"DKeOuOesSVat-Mot-E9wCg","slug":"covent-garden-store","index":true,"theme":"sungod","title":"London Basecamp - SunGod's First UK Store ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/covent-garden-store","slugLocaleMapping":{"covent-garden-store":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-chase-your-vision-chase-your-vision",
    path: "/:locale()/chase-your-vision",
    meta: {"id":"EtEl7Hl8TH2v6Kfmf4I9Vg","slug":"chase-your-vision","index":true,"theme":"sungod","title":"Chase Your Vision with SunGod","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/chase-your-vision","slugLocaleMapping":{"chase-your-vision":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-protect-our-winters-protect-our-winters",
    path: "/:locale()/protect-our-winters",
    meta: {"id":"72065080","slug":"protect-our-winters","index":true,"theme":"sungod","title":"Protect Our Winters UK","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/protect-our-winters","slugLocaleMapping":{"protect-our-winters":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-sunglasses-size-guide-sunglasses-size-guide",
    path: "/:locale()/sunglasses-size-guide",
    meta: {"id":"129609117","slug":"sunglasses-size-guide","index":true,"theme":"sungod","title":"Sunglasses Size Guide","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/sunglasses-size-guide","slugLocaleMapping":{"sunglasses-size-guide":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-black-friday-sunglasses-deals-black-friday-sunglasses-deals",
    path: "/:locale()/black-friday-sunglasses-deals",
    meta: {"id":"qLyGTTPuSWq-jRYScTAMKg","slug":"black-friday-sunglasses-deals","index":true,"theme":"sungod","title":"Black Friday Sunglasses Deals 2024","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/black-friday-sunglasses-deals","slugLocaleMapping":{"black-friday-sunglasses-deals":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-the-2024-vision-raise-the-2024-vision-raise",
    path: "/:locale()/the-2024-vision-raise",
    meta: {"id":"ZuInl0d5SN2MpJfaJhQRCQ","slug":"the-2024-vision-raise","index":true,"theme":"sungod-standalone","title":"The 2024 Vision Raise.","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/the-2024-vision-raise","slugLocaleMapping":{"the-2024-vision-raise":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-refer-a-friend-refer-a-friend",
    path: "/:locale()/refer-a-friend",
    meta: {"id":"On02-9p7QAqVO1tkwbnNxQ","slug":"refer-a-friend","index":true,"theme":"sungod","title":"Refer a Friend","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/refer-a-friend","slugLocaleMapping":{"refer-a-friend":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-competition__strava-challenge__golden-hour-competition__strava-challenge__golden-hour",
    path: "/:locale()/competition/strava-challenge/golden-hour",
    meta: {"id":"MS9F_fj2QvavA3SaiGI-fQ","slug":"competition__strava-challenge__golden-hour","index":true,"theme":"sungod","title":"Golden Hour Strava Challenge","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/competition/strava-challenge/golden-hour","slugLocaleMapping":{"competition__strava-challenge__golden-hour":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-events-purchase-events-purchase",
    path: "/:locale()/events-purchase",
    meta: {"id":"bUB_HwiLQRaKrbJZo0l1sw","slug":"events-purchase","index":true,"theme":"sungod","title":"SunGod Lifetime Guarantee Registration - Events Only ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/events-purchase","slugLocaleMapping":{"events-purchase":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-tile-turnaround-tile-turnaround",
    path: "/:locale()/tile-turnaround",
    meta: {"id":"P4lN7ZadT-G1QpE4ZhAyTw","slug":"tile-turnaround","index":true,"theme":"sungod","title":"Tile Turnaround","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/tile-turnaround","slugLocaleMapping":{"tile-turnaround":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-competition__strava-challenge__factor-50-competition__strava-challenge__factor-50",
    path: "/:locale()/competition/strava-challenge/factor-50",
    meta: {"id":"dZ7YyNooT_mEgo8Qm0gMng","slug":"competition__strava-challenge__factor-50","index":true,"theme":"sungod","title":"Factor 50 Strava Challenge","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/competition/strava-challenge/factor-50","slugLocaleMapping":{"competition__strava-challenge__factor-50":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-create-create",
    path: "/:locale()/create",
    meta: {"id":"0Nz-_eJGSzmov0F6HHPt_g","slug":"create","index":true,"theme":"sungod","title":"SunGod Create","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/create","slugLocaleMapping":{"create":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-one-percent-for-the-planet-one-percent-for-the-planet",
    path: "/:locale()/one-percent-for-the-planet",
    meta: {"id":"Cbe3Ti4pRVO3MJgxqLiw_g","slug":"one-percent-for-the-planet","index":true,"theme":"sungod","title":"SunGod x 1% for the Planet: Sunglasses and Ski Goggles","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/one-percent-for-the-planet","slugLocaleMapping":{"one-percent-for-the-planet":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-goggles-size-guide-goggles-size-guide",
    path: "/:locale()/goggles-size-guide",
    meta: {"id":"172974147","slug":"goggles-size-guide","index":true,"theme":"sungod","title":"Ski and Snowboard Goggles Size Guide","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/goggles-size-guide","slugLocaleMapping":{"goggles-size-guide":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-goggles-size-guide-goggles-size-guide/vanguards-vle_fireN-vf_blackM-vs_blackLP",
    path: "/:locale()/goggles-size-guide/vanguards-vle_fireN-vf_blackM-vs_blackLP",
    meta: {"id":"172974147","slug":"goggles-size-guide","index":false,"theme":"sungod","title":"Ski and Snowboard Goggles Size Guide","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/goggles-size-guide","slugLocaleMapping":{"goggles-size-guide":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]},"sku":"vanguards-vle_fireN-vf_blackM-vs_blackLP"},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-mclaren-winners-sunglasses-mclaren-winners-sunglasses",
    path: "/:locale()/mclaren-winners-sunglasses",
    meta: {"id":"FvocgP82QTSC8UNRCcRIlg","slug":"mclaren-winners-sunglasses","index":true,"theme":"dark-chrome","title":"McLaren Winners' Collection Sunglasses","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/mclaren-winners-sunglasses","slugLocaleMapping":{"mclaren-winners-sunglasses":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-owners_winners-owners_winners",
    path: "/:locale()/owners_winners",
    meta: {"id":"xLAD9VvnTg-xi2FL-GRXrA","slug":"owners_winners","index":true,"theme":"sungod","title":"McLaren Winners Owners' Guide ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/owners_winners","slugLocaleMapping":{"owners_winners":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-create-terms-and-conditions-create-terms-and-conditions",
    path: "/:locale()/create-terms-and-conditions",
    meta: {"id":"ISJ4mHGBT5677kcxf2ygWg","slug":"create-terms-and-conditions","index":true,"theme":"sungod","title":"SunGod Create - Terms & Conditions Of Sale","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/create-terms-and-conditions","slugLocaleMapping":{"create-terms-and-conditions":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-co-brand-co-brand",
    path: "/:locale()/co-brand",
    meta: {"id":"146687282","slug":"co-brand","index":true,"theme":"sungod","title":"Co-Brand","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/co-brand","slugLocaleMapping":{"co-brand":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-see-better-see-better",
    path: "/:locale()/see-better",
    meta: {"id":"173170654","slug":"see-better","index":true,"theme":"sungod","title":"See Better with SunGod Sunglasses. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/see-better","slugLocaleMapping":{"see-better":["en-gb","en-us","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-shop__infinite-recycled-frames-shop__infinite-recycled-frames",
    path: "/:locale()/shop/infinite-recycled-frames",
    meta: {"id":"63538716","slug":"shop__infinite-recycled-frames","index":true,"theme":"sungod","title":"Sunglasses with 100% Recycled Frames","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/shop/infinite-recycled-frames","slugLocaleMapping":{"shop__infinite-recycled-frames":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-itv-competition-itv-competition",
    path: "/:locale()/itv-competition",
    meta: {"id":"185268681","slug":"itv-competition","index":true,"theme":"sungod","title":"ITV Competition","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/itv-competition","slugLocaleMapping":{"itv-competition":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-shop__limited-edition__james-woods-shop__limited-edition__james-woods",
    path: "/:locale()/shop/limited-edition/james-woods",
    meta: {"id":"95340275","slug":"shop__limited-edition__james-woods","index":true,"theme":"sungod","title":"James 'Woodsy' Woods. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/shop/limited-edition/james-woods","slugLocaleMapping":{"shop__limited-edition__james-woods":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-design-your-pair-challenge-design-your-pair-challenge",
    path: "/:locale()/design-your-pair-challenge",
    meta: {"id":"173002726","slug":"design-your-pair-challenge","index":true,"theme":"sungod","title":"SunGod. Design Your Pair Strava Challenge. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/design-your-pair-challenge","slugLocaleMapping":{"design-your-pair-challenge":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-TDF-TDF",
    path: "/:locale()/TDF",
    meta: {"id":"143091794","slug":"TDF","index":true,"theme":"sungod","title":"See Better with SunGod Sunglasses. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/TDF","slugLocaleMapping":{"TDF":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-sungod-x-fastandcurious-competition-sungod-x-fastandcurious-competition",
    path: "/:locale()/sungod-x-fastandcurious-competition",
    meta: {"id":"141946203","slug":"sungod-x-fastandcurious-competition","index":true,"theme":"sungod","title":"McLaren F1 x SunGod Competition. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/sungod-x-fastandcurious-competition","slugLocaleMapping":{"sungod-x-fastandcurious-competition":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-spencer-spencer",
    path: "/:locale()/spencer",
    meta: {"id":"138385272","slug":"spencer","index":true,"theme":"sungod","title":"SunGod x Big Fish Podcast. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/spencer","slugLocaleMapping":{"spencer":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-london-london",
    path: "/:locale()/london",
    meta: {"id":"130104136","slug":"london","index":true,"theme":"sungod","title":"See Better with SunGod Sunglasses. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/london","slugLocaleMapping":{"london":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-tube-tube",
    path: "/:locale()/tube",
    meta: {"id":"106393254","slug":"tube","index":true,"theme":"sungod","title":"See Better with SunGod Sunglasses. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/tube","slugLocaleMapping":{"tube":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-see-better-challenge-see-better-challenge",
    path: "/:locale()/see-better-challenge",
    meta: {"id":"119019814","slug":"see-better-challenge","index":true,"theme":"sungod","title":"SunGod. See Better Strava Challenge. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/see-better-challenge","slugLocaleMapping":{"see-better-challenge":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-geraint-geraint",
    path: "/:locale()/geraint",
    meta: {"id":"117978536","slug":"geraint","index":true,"theme":"sungod","title":"Geraint Thomas Cycling Club","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/geraint","slugLocaleMapping":{"geraint":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-the-bike-project-the-bike-project",
    path: "/:locale()/the-bike-project",
    meta: {"id":"63538691","slug":"the-bike-project","index":true,"theme":"sungod","title":"Meet Our Partners: The Bike Project","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/the-bike-project","slugLocaleMapping":{"the-bike-project":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-shop__limited-edition__katie-ormerod-shop__limited-edition__katie-ormerod",
    path: "/:locale()/shop/limited-edition/katie-ormerod",
    meta: {"id":"95311247","slug":"shop__limited-edition__katie-ormerod","index":true,"theme":"sungod","title":"Katie Ormerod Signature Series. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/shop/limited-edition/katie-ormerod","slugLocaleMapping":{"shop__limited-edition__katie-ormerod":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-the-2022-vision-raise-the-2022-vision-raise",
    path: "/:locale()/the-2022-vision-raise",
    meta: {"id":"142780024","slug":"the-2022-vision-raise","index":true,"theme":"sungod-standalone","title":"The 2022 Vision Raise.","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/the-2022-vision-raise","slugLocaleMapping":{"the-2022-vision-raise":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-sungod-x-mclaren-competition-sungod-x-mclaren-competition",
    path: "/:locale()/sungod-x-mclaren-competition",
    meta: {"id":"123048332","slug":"sungod-x-mclaren-competition","index":true,"theme":"sungod","title":"McLaren F1 x SunGod Competition. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/sungod-x-mclaren-competition","slugLocaleMapping":{"sungod-x-mclaren-competition":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-momentum-momentum",
    path: "/:locale()/momentum",
    meta: {"id":"121541279","slug":"momentum","index":true,"theme":"sungod","title":"The Momentum Campaign. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/momentum","slugLocaleMapping":{"momentum":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-shop__limited-edition__england-rugby-shop__limited-edition__england-rugby",
    path: "/:locale()/shop/limited-edition/england-rugby",
    meta: {"id":"96874845","slug":"shop__limited-edition__england-rugby","index":true,"theme":"sungod","title":"SunGod x England Rugby. ","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/shop/limited-edition/england-rugby","slugLocaleMapping":{"shop__limited-edition__england-rugby":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "glp-freeride-world-tour-freeride-world-tour",
    path: "/:locale()/freeride-world-tour",
    meta: {"id":"72065085","slug":"freeride-world-tour","index":true,"theme":"sungod","title":"Freeride World Tour","extendedPage":true,"pageType":"Landing Page","unlocalisedPath":"/freeride-world-tour","slugLocaleMapping":{"freeride-world-tour":["en-gb","en-au","en-ca","en-ch","en-de","en-fr","en-ie","en-nz","en-us","en-ae","en-br","en-at","en-be","en-cl","en-cy","en-dk","en-es","en-fi","en-gr","en-hk","en-hr","en-hu","en-id","en-in","en-it","en-jp","en-lu","en-mx","en-nl","en-no","en-pl","en-pt","en-sa","en-se","en-sg","en-za"]}},
    component: () => import("/vercel/path0/pages/[locale]/generic-landing-page/[...generic-landing-page].vue")
  },
  {
    name: "pdp-tokas",
    path: "/:locale()/shop/sunglasses/tokas",
    meta: {"sku":"tokas","baseSku":"tokas","id":"118023005","index":true,"title":"SunGod Tokas™ Sunglasses ","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/tokas"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-miras",
    path: "/:locale()/shop/sunglasses/miras",
    meta: {"sku":"miras","baseSku":"miras","id":"UKAC3XfDR3mMbC09po4Srg","index":true,"title":"SunGod Miras™ Sunglasses ","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/miras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs",
    path: "/:locale()/shop/sunglasses/zephyrs",
    meta: {"sku":"zephyrs","baseSku":"zephyrs","id":"122126609","index":true,"title":"SunGod Zephyrs™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/zephyrs"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ultras",
    path: "/:locale()/shop/sunglasses/ultras",
    meta: {"sku":"ultras","baseSku":"ultras","id":"116109032","index":true,"title":"SunGod Ultras™ - Running Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/ultras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_mclaren25",
    path: "/:locale()/shop/limited-edition/mclaren-renegades-25",
    meta: {"sku":"renegades-le_mclaren25","baseSku":"renegades","id":"O2NlibTHQ8-KIZQfTdAkXQ","index":true,"title":"McLaren F1 Team SunGod Renegades™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-renegades-25"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_mclaren25-plus",
    path: "/:locale()/shop/limited-edition/mclaren25-renegades-plus",
    meta: {"sku":"renegades-le_mclaren25-plus","baseSku":"renegades","id":"HWakJstDSMybWZqPYHTKxw","index":true,"title":" SunGod McLaren F1 Team Renegades™ Plus","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren25-renegades-plus"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vulcans-le_mclaren25",
    path: "/:locale()/shop/limited-edition/mclaren-vulcans-25",
    meta: {"sku":"vulcans-le_mclaren25","baseSku":"vulcans","id":"eLF8TMaqT56Xh_A7elDnFQ","index":true,"title":"McLaren Limited Edition Vulcans™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-vulcans-25"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-case-ct_revoltcapsule",
    path: "/:locale()/shop/cases/goggles-capsule-case",
    meta: {"sku":"case-ct_revoltcapsule","baseSku":"case-ct_revoltcapsule","id":"RGc_A2MTTCSn7NFif5DBCg","index":true,"title":"Snow Series™ Capsule Case","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/cases/goggles-capsule-case"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-miras-le_mclaren25",
    path: "/:locale()/shop/limited-edition/mclaren25-miras",
    meta: {"sku":"miras-le_mclaren25","baseSku":"miras","id":"SgBV0Yn6QaO-6hihDzBTqg","index":true,"title":"Limited Edition McLaren Miras™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren25-miras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_op81",
    path: "/:locale()/shop/limited-edition/oscar-piastri-renegades",
    meta: {"sku":"renegades-le_op81","baseSku":"renegades","id":"MSQwKphHRSiCF6QUlPt7ug","index":true,"title":"Oscar Piastri x SunGod OP81 Renegades™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/oscar-piastri-renegades"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vulcans",
    path: "/:locale()/shop/sunglasses/vulcans",
    meta: {"sku":"vulcans","baseSku":"vulcans","id":"63537384","index":true,"title":"SunGod Vulcans™ Cycling Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/vulcans"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_mclaren25",
    path: "/:locale()/shop/limited-edition/mclaren25-zephyrs",
    meta: {"sku":"zephyrs-le_mclaren25","baseSku":"zephyrs","id":"HHHqnK38QWeSQxX_m6jASg","index":true,"title":"Limited Edition McLaren Zephyrs Sunglasses.","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren25-zephyrs"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-snipers",
    path: "/:locale()/shop/goggles/snipers",
    meta: {"sku":"snipers","baseSku":"snipers","id":"63537444","index":true,"title":"SunGod Snipers™ Goggles","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/goggles/snipers"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ullrs",
    path: "/:locale()/shop/goggles/ullrs",
    meta: {"sku":"ullrs","baseSku":"ullrs","id":"JOxw40i8RnmJfHTVxZooWQ","index":true,"title":"SunGod Ullrs™ Goggles","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/goggles/ullrs"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-tokas-le_mclaren60th",
    path: "/:locale()/shop/limited-edition/mclaren-60th-tokas",
    meta: {"sku":"tokas-le_mclaren60th","baseSku":"tokas","id":"172038964","index":true,"title":"McLaren F1 Team 60th Edition Tokas™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-60th-tokas"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_mclaren24",
    path: "/:locale()/shop/limited-edition/mclaren-zephyrs",
    meta: {"sku":"zephyrs-le_mclaren24","baseSku":"zephyrs","id":"ParremfZTZmkL1OVgxBw1A","index":true,"title":"Limited Edition McLaren Zephyrs Sunglasses.","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-zephyrs"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vulcans-le_mclaren24",
    path: "/:locale()/shop/limited-edition/mclaren-vulcans",
    meta: {"sku":"vulcans-le_mclaren24","baseSku":"vulcans","id":"GMLCFn_qQhiJOTwepMDu8A","index":true,"title":"McLaren Limited Edition Vulcans™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-vulcans"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sierras-le_ln4",
    path: "/:locale()/shop/limited-edition/mclaren-lando-norris",
    meta: {"sku":"sierras-le_ln4","baseSku":"sierras","id":"69567203","index":true,"title":"SunGod Lando LN4™ Sierras","theme":"ln4","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-lando-norris"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_silverstone",
    path: "/:locale()/shop/limited-edition/lando-norris-zephyrs-silverstone",
    meta: {"sku":"zephyrs-le_silverstone","baseSku":"zephyrs","id":"133591413","index":true,"title":" Lando Norris Silverstone Zephyrs™","theme":"ln4","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/lando-norris-zephyrs-silverstone"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-velans",
    path: "/:locale()/shop/sunglasses/velans",
    meta: {"sku":"velans","baseSku":"velans","id":"63537411","index":true,"title":"SunGod Velans™ Cycling Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/velans"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vanguards",
    path: "/:locale()/shop/goggles/vanguards",
    meta: {"sku":"vanguards","baseSku":"vanguards","id":"63537442","index":true,"title":"SunGod Vanguards™ Goggles","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/goggles/vanguards"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ultras-le_tomevans",
    path: "/:locale()/shop/signature-series/tom-evans-ultras-sunglasses",
    meta: {"sku":"ultras-le_tomevans","baseSku":"Ultras","id":"134166787","index":true,"title":"SunGod Tom Evans Signature Series","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/signature-series/tom-evans-ultras-sunglasses"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ultras-le_hayden",
    path: "/:locale()/shop/signature-series/hayden-wilde-ultras-sunglasses",
    meta: {"sku":"ultras-le_hayden","baseSku":"Ultras","id":"N9k595xBSkGrX0Ccgm75JA","index":true,"title":"Hayden Wilde Signature Series Ultras™","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/signature-series/hayden-wilde-ultras-sunglasses"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-gts",
    path: "/:locale()/shop/sunglasses/gts",
    meta: {"sku":"gts","baseSku":"gts","id":"116703971","index":true,"title":"SunGod GTs™ - Cycling Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/gts"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-tempests",
    path: "/:locale()/shop/sunglasses/tempests",
    meta: {"sku":"tempests","baseSku":"tempests","id":"121923781","index":true,"title":"SunGod Tempests™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/tempests"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-airas",
    path: "/:locale()/shop/sunglasses/airas",
    meta: {"sku":"airas","baseSku":"airas","id":"63537476","index":true,"title":"SunGod Airas™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/airas"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades",
    path: "/:locale()/shop/sunglasses/renegades",
    meta: {"sku":"renegades","baseSku":"renegades","id":"63537438","index":true,"title":"SunGod Renegades™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/renegades"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sierras",
    path: "/:locale()/shop/sunglasses/sierras",
    meta: {"sku":"sierras","baseSku":"sierras","id":"63537440","index":true,"title":"SunGod Sierras™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/sierras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-classics3",
    path: "/:locale()/shop/sunglasses/classics3",
    meta: {"sku":"classics3","baseSku":"classics3","id":"63537436","index":true,"title":"SunGod Classics³ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/sunglasses/classics3"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_woodsy",
    path: "/:locale()/shop/signature-series/woodsy-renegades",
    meta: {"sku":"renegades-le_woodsy","baseSku":"renegades","id":"69567299","index":true,"title":"Woodsy Signature Series Renegades™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/signature-series/woodsy-renegades"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-classics4-le_williams",
    path: "/:locale()/shop/limited-edition/williams-f1-classics4",
    meta: {"sku":"classics4-le_williams","baseSku":"classics3","id":"LHgBF-mYTX2g1k1Y2IfBNw","index":true,"title":"SunGod Williams Racing Classics³: Official Eyewear Supplier","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/williams-f1-classics4"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-velansck",
    path: "/:locale()/shop/parts/velans",
    meta: {"sku":"velansck","baseSku":"velans","id":"63537421","index":true,"title":" SunGod Velans™ Conversion Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/velans"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-case-ct_compactvanguards",
    path: "/:locale()/shop/cases/goggles-compact-case",
    meta: {"sku":"case-ct_compactvanguards","baseSku":"case-ct_compactvanguards","id":"dM3nGAU0TUKynS8nVw5BRQ","index":true,"title":"Snow Series™ Compact Case","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/cases/goggles-compact-case"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-case-ct_compactsnipers",
    path: "/:locale()/shop/cases/snipers-compact-case",
    meta: {"sku":"case-ct_compactsnipers","baseSku":"case-ct_compactsnipers","id":"CSPkPWs8TAemUnJ2Apyp7w","index":true,"title":"Snipers™ Compact Case","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/cases/snipers-compact-case"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-case-ct_pacecapsule",
    path: "/:locale()/shop/cases/pace-series-capsule-case",
    meta: {"sku":"case-ct_pacecapsule","baseSku":"case-ct_pacecapsule","id":"SnDDAQyxQj2rmTr4Mh2Pew","index":true,"title":"Pace Series™ Capsule Case","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/cases/pace-series-capsule-case"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-case-ct_capsule",
    path: "/:locale()/shop/cases/sunglasses-capsule-case",
    meta: {"sku":"case-ct_capsule","baseSku":"case-ct_capsule","id":"ND9lVWj_RECMdM7hXHQi8A","index":true,"title":"Lifestyle Series™ Capsule Case","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/cases/sunglasses-capsule-case"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-cap-le_newera950sm",
    path: "/:locale()/shop/limited-edition/new-era-9-fifty-cap-small-medium",
    meta: {"sku":"cap-le_newera950sm","baseSku":"cap-le_newera950","id":"Mj1zoQ7yQvK3hbUkpc0-NA","index":true,"title":"SunGod x New Era Headwear Collection. ","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-9-fifty-cap-small-medium"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-cap-le_newera950ml",
    path: "/:locale()/shop/limited-edition/new-era-9-fifty-cap-medium-large",
    meta: {"sku":"cap-le_newera950ml","baseSku":"cap-le_newera950","id":"U6dVYl44TQahqj5erOm9pQ","index":true,"title":"9FIFTY Cap: SunGod x New Era","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-9-fifty-cap-medium-large"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-cap-le_newera950",
    path: "/:locale()/shop/limited-edition/new-era-9-fifty-cap",
    meta: {"sku":"cap-le_newera950","baseSku":"cap-le_newera950","id":"T5TdPijlREincoQfEYgl4A","index":true,"title":"SunGod x New Era Headwear Collection. ","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-9-fifty-cap"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-cap-le_newera940",
    path: "/:locale()/shop/limited-edition/new-era-9-forty-cap",
    meta: {"sku":"cap-le_newera940","baseSku":"cap-le_newera940","id":"amn0s5feTm2KqsINANQfng","index":true,"title":"9FORTY Cap: SunGod x New Era ","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-9-forty-cap"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-lenscleankit",
    path: "/:locale()/shop/accessories/lens-cleaning-kit",
    meta: {"sku":"lenscleankit","baseSku":"","id":"Z9GY8-hPRLqgVlj5MBYJrA","index":true,"title":"Lens Cleaning Kit","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/lens-cleaning-kit"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ge_microfibre-1ftp",
    path: "/:locale()/shop/accessories/1-percent-for-the-planet-microfibre",
    meta: {"sku":"ge_microfibre-1ftp","baseSku":"microfiber","id":"LJPF1v41RqeoEVmKSv5nHA","index":true,"title":"1% for the Planet Charity Microfibre Pouch","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/1-percent-for-the-planet-microfibre"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_ln4clear",
    path: "/:locale()/shop/limited-edition/lando-norris-clear-zephyrs",
    meta: {"sku":"zephyrs-le_ln4clear","baseSku":"zephyrs","id":"DrNtkkikSs-wgd85gqQ5ag","index":true,"title":"Limited Edition Lando Norris Sunglasses - SunGod Zephyrs™","theme":"ln4","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/lando-norris-clear-zephyrs"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-gogglelensprotector-gc_1ftp",
    path: "/:locale()/shop/accessories/goggle-lens-protector/1-percent-for-the-planet",
    meta: {"sku":"gogglelensprotector-gc_1ftp","baseSku":"gogglelensprotector","id":"f8XF0U_LTNmpn1WDw5UEWQ","index":true,"title":"1% for the Planet Goggle Lens Protector","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/goggle-lens-protector/1-percent-for-the-planet"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_sas",
    path: "/:locale()/shop/limited-edition/sas-renegades",
    meta: {"sku":"renegades-le_sas","baseSku":"renegades","id":"122678597","index":true,"title":"Surfers Against Sewage Collection - Renegades™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/sas-renegades"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-microfibre-le_pow23",
    path: "/:locale()/shop/microfiber-le_pow23",
    meta: {"sku":"microfibre-le_pow23","baseSku":"microfiber","id":"144168445","index":true,"theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/microfiber-le_pow23"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ge_microfiber-pow25",
    path: "/:locale()/shop/protect-our-winters-microfibre-2025",
    meta: {"sku":"ge_microfiber-pow25","baseSku":"microfiber","id":"EvbyQsIST2CRIzT8Z_cS7Q","index":true,"title":"Protect Our Winters Charity Microfibre Pouch ","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/protect-our-winters-microfibre-2025"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-velans-le_tompidcock",
    path: "/:locale()/shop/limited-edition/tom-pidock-velans-sunglasses",
    meta: {"sku":"velans-le_tompidcock","baseSku":"velans","id":"122225553","index":true,"title":"Tom Pidcock Signature Series Velans™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/tom-pidock-velans-sunglasses"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vulcans-le_pauline",
    path: "/:locale()/shop/limited-edition/pauline-ferrand-prevot-vulcans",
    meta: {"sku":"vulcans-le_pauline","baseSku":"vulcans","id":"fwURvGsXSk2S8dX4ZkrHJQ","index":true,"title":"Pauline Ferrand-Prévot Limited Edition Vulcans™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/pauline-ferrand-prevot-vulcans"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-microfibre-le_snowx2",
    path: "/:locale()/shop/accessories/snow-microfibre-kit",
    meta: {"sku":"microfibre-le_snowx2","baseSku":"microfibre","id":"VaipNX3ITSaTgauNuolb1A","index":true,"title":"Snow Microfibre Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/snow-microfibre-kit"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-miras-le_grape",
    path: "/:locale()/shop/limited-edition/grape-miras",
    meta: {"sku":"miras-le_grape","baseSku":"miras","id":"VnYluIUEQoqFtQMWi9N9LA","index":true,"title":"Limited Edition Grape Miras™","theme":"grape","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/grape-miras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vulcans-le_newera",
    path: "/:locale()/shop/limited-edition/new-era-vulcans-sunglasses",
    meta: {"sku":"vulcans-le_newera","baseSku":"vulcans","id":"GTT7jgxnSFWYtLmJOC7PFw","index":true,"title":"Limited Edition SunGod x New Era Vulcans™ Sunglasses","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-vulcans-sunglasses"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-lifestylecarebundle",
    path: "/:locale()/shop/accessories/lifestyle-care-bundle",
    meta: {"sku":"lifestylecarebundle","baseSku":"","id":"YeaGYcG_QbmBZ7jU6h7nyw","index":true,"title":"Lifestyle Series Care Bundle","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/lifestyle-care-bundle"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-pacecarebundle",
    path: "/:locale()/shop/accessories/pace-care-bundle",
    meta: {"sku":"pacecarebundle","baseSku":"","id":"O162r42uSeOF3FgvfjzgbA","index":true,"title":"Pace Series Care Bundle","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/pace-care-bundle"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-airas-le_ineosgrenadiers",
    path: "/:locale()/shop/limited-edition/ineos-grenadiers-airas",
    meta: {"sku":"airas-le_ineosgrenadiers","baseSku":"airas","id":"106412047","index":true,"title":"INEOS Grenadiers Team Edition Airas™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ineos-grenadiers-airas"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_mclaren24lasvegas",
    path: "/:locale()/shop/limited-edition/las-vegas-mclaren-zephyrs",
    meta: {"sku":"zephyrs-le_mclaren24lasvegas","baseSku":"zephyrs","id":"Tt55dfZeTMObQNukg1HL3A","index":true,"title":"SunGod x McLaren Las Vegas Zephyrs™ Sunglasses","theme":"purple","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/las-vegas-mclaren-zephyrs"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-gts-le_geraintthomas",
    path: "/:locale()/shop/limited-edition/geraint-thomas-gts-sunglasses",
    meta: {"sku":"gts-le_geraintthomas","baseSku":"gts-le_geraintthomas","id":"116703945","index":true,"title":"SunGod GTs Signature Series™","theme":"silverblue","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/geraint-thomas-gts-sunglasses"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vulcans-le_tekkerz",
    path: "/:locale()/shop/limited-edition/tekkerz",
    meta: {"sku":"vulcans-le_tekkerz","baseSku":"vulcans","id":"143334600","index":true,"title":"Tekkerz Limited Edition Vulcanz™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/tekkerz"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vulcans-le_padrescc",
    path: "/:locale()/shop/limited-edition/vulcans-le_padres-cc",
    meta: {"sku":"vulcans-le_padrescc","baseSku":"vulcans","id":"APRfWVhiSTC2OMbi8ze7Gw","index":true,"title":"SD Padres City Connect Vulcans™: Official Eyewear Supplier","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/vulcans-le_padres-cc"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vulcans-le_padres",
    path: "/:locale()/shop/limited-edition/vulcans-le_padres",
    meta: {"sku":"vulcans-le_padres","baseSku":"vulcans","id":"Q8yFPCMkQL2J7_WTX-3NuQ","index":true,"title":"San Diego Padres Vulcans™: Official Eyewear Supplier","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/vulcans-le_padres"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vulcans-le_momentum",
    path: "/:locale()/shop/limited-edition/momentum-vulcans",
    meta: {"sku":"vulcans-le_momentum","baseSku":"vulcans","id":"122601864","index":true,"title":"SunGod Momentum Vulcans™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/momentum-vulcans"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vulcans-le_ineosgrenadiers",
    path: "/:locale()/shop/limited-edition/ineos-grenadiers-vulcans",
    meta: {"sku":"vulcans-le_ineosgrenadiers","baseSku":"vulcans","id":"106462647","index":true,"title":"INEOS Grenadiers Team Edition Vulcans™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ineos-grenadiers-vulcans"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-velans-le_momentum",
    path: "/:locale()/shop/limited-edition/momentum-velans",
    meta: {"sku":"velans-le_momentum","baseSku":"velans","id":"122604808","index":true,"title":"SunGod Momentum Velans™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/momentum-velans"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-velans-le_ineosgrenadiers",
    path: "/:locale()/shop/limited-edition/ineos-grenadiers-velans",
    meta: {"sku":"velans-le_ineosgrenadiers","baseSku":"velans","id":"106462652","index":true,"title":"INEOS Grenadiers Team Edition Velans™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ineos-grenadiers-velans"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-velans-le_canyon",
    path: "/:locale()/shop/limited-edition/canyon-dhb-sungod",
    meta: {"sku":"velans-le_canyon","baseSku":"velans","id":"102391857","index":true,"title":"Canyon DHB SunGod Limited Edition Velans™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/canyon-dhb-sungod"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-airas-le_wiv",
    path: "/:locale()/shop/limited-edition/wiv-sungod",
    meta: {"sku":"airas-le_wiv","baseSku":"airas","id":"121755307","index":true,"title":"WiV SunGod Limited Edition Velans™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/wiv-sungod"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_yankees",
    path: "/:locale()/shop/limited-edition/zephyrs-le_yankees",
    meta: {"sku":"zephyrs-le_yankees","baseSku":"zephyrs","id":"V79DhfT2SX6ZBiMhTbVUkQ","index":true,"title":"New York Yankees Zephyrs™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/zephyrs-le_yankees"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_solstice",
    path: "/:locale()/shop/limited-edition/solstice-zephyrs",
    meta: {"sku":"zephyrs-le_solstice","baseSku":"zephyrs","id":"dby2dnJvR8im4N0ZRemV2Q","index":true,"title":"Summer Solstice Limited Edition Zephyrs™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/solstice-zephyrs"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_redsox",
    path: "/:locale()/shop/limited-edition/zephyrs-le_redsox",
    meta: {"sku":"zephyrs-le_redsox","baseSku":"zephyrs","id":"TIaLtUzwRxS6ztl2FeehpQ","index":true,"title":"Boston Red Sox Zephyrs™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/zephyrs-le_redsox"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_padres",
    path: "/:locale()/shop/limited-edition/zephyrs-le_padres",
    meta: {"sku":"zephyrs-le_padres","baseSku":"zephyrs","id":"V_82_s8VSLSE9cfJd9cCVQ","index":true,"title":"San Diego Padres Zephyrs™: Official Eyewear Supplier","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/zephyrs-le_padres"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_newera",
    path: "/:locale()/shop/limited-edition/new-era-zephyrs-sunglasses",
    meta: {"sku":"zephyrs-le_newera","baseSku":"zephyrs","id":"JPnQ6nPtRyy9-sJ0NACMrQ","index":true,"title":"Limited Edition SunGod x New Era Zephyrs™ Sunglasses.","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-zephyrs-sunglasses"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_monaco23",
    path: "/:locale()/shop/limited-edition/mclaren-monaco-zephyrs",
    meta: {"sku":"zephyrs-le_monaco23","baseSku":"Zephyrs","id":"110553740","index":true,"title":"McLaren F1 Monaco Triple Crown Zephyrs™️ ","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-monaco-zephyrs"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_ln4",
    path: "/:locale()/shop/limited-edition/lando-norris-zephyrs",
    meta: {"sku":"zephyrs-le_ln4","baseSku":"zephyrs","id":"122221369","index":true,"title":"Limited Edition Lando Norris Sunglasses - Zephyrs™","theme":"ln4","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/lando-norris-zephyrs"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_giants",
    path: "/:locale()/shop/limited-edition/zephyrs-le_giants",
    meta: {"sku":"zephyrs-le_giants","baseSku":"zephyrs","id":"IES5C9myThWPw7IekfQKsg","index":true,"title":"San Francisco Giants Zephyrs™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/zephyrs-le_giants"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_dodgers",
    path: "/:locale()/shop/limited-edition/zephyrs-le_dodgers",
    meta: {"sku":"zephyrs-le_dodgers","baseSku":"zephyrs","id":"D4ELoef2Qyit7QyCnapwtQ","index":true,"title":"LA Dodgers Zephyrs™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/zephyrs-le_dodgers"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_astros",
    path: "/:locale()/shop/limited-edition/zephyrs-le_astros",
    meta: {"sku":"zephyrs-le_astros","baseSku":"zephyrs","id":"KkeunvNqSgaC73xIzCyfdg","index":true,"title":"Houston Astros Zephyrs™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/zephyrs-le_astros"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrs-le_airton",
    path: "/:locale()/shop/signature-series/airton-cozzolino-zephyrs-sunglasses",
    meta: {"sku":"zephyrs-le_airton","baseSku":"Zephyrs","id":"118500741","index":true,"title":"SunGod Airton Cozzolino Signature Series","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/signature-series/airton-cozzolino-zephyrs-sunglasses"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-tokas-le_solstice",
    path: "/:locale()/shop/limited-edition/solstice-tokas",
    meta: {"sku":"tokas-le_solstice","baseSku":"tokas","id":"YgVshCF5TdmoN4ayxZeg8g","index":true,"title":"Summer Solstice Limited Edition Tokas™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/solstice-tokas"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-tokas-le_mclarenwinners",
    path: "/:locale()/shop/mclaren/winners",
    meta: {"sku":"tokas-le_mclarenwinners","baseSku":"tokas","id":"AIOtQiOuTEisSffaltHEfA","index":true,"title":"SunGod x McLaren Winners' Collection Sunglasses","theme":"dark-chrome","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/mclaren/winners"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-tempests-le_sailgp",
    path: "/:locale()/shop/limited-edition/sailgp-tempests",
    meta: {"sku":"tempests-le_sailgp","baseSku":"tempests","id":"143783828","index":true,"title":"SunGod x Great Britain SailGP Team Collection - Tempests™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/sailgp-tempests"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-tempests-le_mclarenplus",
    path: "/:locale()/shop/limited-edition/mclaren-plus-tempests-polarised-sunglasses",
    meta: {"sku":"tempests-le_mclarenplus","baseSku":"tempests","id":"119038077","index":true,"title":"SunGod McLaren F1 Team Tempests™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-plus-tempests-polarised-sunglasses"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-tempests-le_mclarenmx",
    path: "/:locale()/shop/limited-edition/mclaren-extreme-e-tempests",
    meta: {"sku":"tempests-le_mclarenmx","baseSku":"tempests","id":"122433054","index":true,"title":"SunGod NEOM McLaren Extreme E Tempests™","theme":"mclaren-mx","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-extreme-e-tempests"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-tempests-le_ineosbritannia",
    path: "/:locale()/shop/limited-edition/ineos-britannia-tempests",
    meta: {"sku":"tempests-le_ineosbritannia","baseSku":"tempests","id":"144993453","index":true,"title":"INEOS Britannia Tempests™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ineos-britannia-tempests"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sierras-le_sas",
    path: "/:locale()/shop/limited-edition/sas-sierras",
    meta: {"sku":"sierras-le_sas","baseSku":"sierras","id":"122607157","index":true,"title":"Surfers Against Sewage - Sierras™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/sas-sierras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sierras-le_sailgp",
    path: "/:locale()/shop/limited-edition/sailgp-sierras",
    meta: {"sku":"sierras-le_sailgp","baseSku":"sierras","id":"122520229","index":true,"title":"Great Britain SailGP Sierras™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/sailgp-sierras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sierras-le_mclarenplus",
    path: "/:locale()/shop/limited-edition/mclaren-fire-sierras",
    meta: {"sku":"sierras-le_mclarenplus","baseSku":"sierras","id":"69566624","index":true,"title":"SunGod McLaren F1 Team Sierras™ - Fire","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-fire-sierras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sierras-le_mclarenmonaco",
    path: "/:locale()/shop/limited-edition/mclaren-monaco-sierras",
    meta: {"sku":"sierras-le_mclarenmonaco","baseSku":"sierras","id":"121508419","index":true,"title":"McLaren Monaco Sierras™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-monaco-sierras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sierras-le_mclaren24monaco",
    path: "/:locale()/shop/limited-edition/mclaren-monaco-sierras-2024",
    meta: {"sku":"sierras-le_mclaren24monaco","baseSku":"sierras","id":"eQJ6CPPmTg6lYrVKJ_PzHg","index":true,"title":"McLaren F1 Team Monaco Limited Edition Sierras™ ","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-monaco-sierras-2024"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sierras-le_mclaren24",
    path: "/:locale()/shop/limited-edition/mclaren-smoke-sierras-2024",
    meta: {"sku":"sierras-le_mclaren24","baseSku":"sierras","id":"Ao8Vq_eSSrKBv-p44y0mxQ","index":true,"title":"SunGod McLaren F1 Team Sierras™ ","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-smoke-sierras-2024"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sierras-le_mclaren",
    path: "/:locale()/shop/limited-edition/mclaren-smoke-sierras",
    meta: {"sku":"sierras-le_mclaren","baseSku":"sierras","id":"68407946","index":true,"title":"SunGod McLaren F1 Team Sierras™ - Smoke","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-smoke-sierras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sierras-le_katieo",
    path: "/:locale()/shop/signature-series/katie-ormerod",
    meta: {"sku":"sierras-le_katieo","baseSku":"sierras","id":"100386006","index":true,"title":"Katie Ormerod Signature Series Sierras™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/signature-series/katie-ormerod"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sierras-le_ineosbritannia",
    path: "/:locale()/shop/limited-edition/ineos-britannia-sierras",
    meta: {"sku":"sierras-le_ineosbritannia","baseSku":"sierras","id":"144993428","index":true,"title":"Limited Edition INEOS Britannia Sierras™","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ineos-britannia-sierras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_yankees",
    path: "/:locale()/shop/limited-edition/renegades-le_yankees",
    meta: {"sku":"renegades-le_yankees","baseSku":"renegades","id":"Uxi0ojXDQsOIKaMRc6mRpw","index":true,"title":"New York Yankees Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/renegades-le_yankees"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_williams",
    path: "/:locale()/shop/limited-edition/williams-f1-renegades",
    meta: {"sku":"renegades-le_williams","baseSku":"renegades","id":"BEbo3Qj_RE2nvWPTpqIvjg","index":true,"title":"SunGod Williams Racing Renegades™: Official Eyewear Supplier","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/williams-f1-renegades"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_rfu",
    path: "/:locale()/shop/limited-edition/rfu-renegades",
    meta: {"sku":"renegades-le_rfu","baseSku":"renegades","id":"69566904","index":true,"title":"SunGod x RFU 150 Renegades™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/rfu-renegades"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_redsox",
    path: "/:locale()/shop/limited-edition/renegades-le_redsox",
    meta: {"sku":"renegades-le_redsox","baseSku":"renegades","id":"K58L-f9yQ1m-RoWAoxLQEg","index":true,"title":"Boston Red Sox Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/renegades-le_redsox"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_padres",
    path: "/:locale()/shop/limited-edition/renegades-le_padres",
    meta: {"sku":"renegades-le_padres","baseSku":"renegades","id":"XLMkjPFjRzuYqcsRKZEdJA","index":true,"title":"San Diego Padres Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/renegades-le_padres"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_mclarenplus",
    path: "/:locale()/shop/limited-edition/mclaren-fire-renegades",
    meta: {"sku":"renegades-le_mclarenplus","baseSku":"renegades","id":"69566774","index":true,"title":" SunGod McLaren F1 Team Renegades™ - Fire","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-fire-renegades"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_mclarenmiami23",
    path: "/:locale()/shop/limited-edition/mclaren-miami-renegades-23",
    meta: {"sku":"renegades-le_mclarenmiami23","baseSku":"renegades","id":"106393267","index":true,"title":"McLaren Miami Renegades™","theme":"miami","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-miami-renegades-23"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_mclarenmiami",
    path: "/:locale()/shop/limited-edition/mclaren-miami-renegades",
    meta: {"sku":"renegades-le_mclarenmiami","baseSku":"renegades","id":"121269455","index":true,"title":"McLaren Miami Renegades™","theme":"miami","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-miami-renegades"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_mclaren24",
    path: "/:locale()/shop/limited-edition/mclaren-smoke-renegades-2024",
    meta: {"sku":"renegades-le_mclaren24","baseSku":"renegades","id":"f7SZtdKdRyKQRKVaaeCVPA","index":true,"title":"SunGod McLaren F1 Team Renegades™","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-smoke-renegades-2024"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_mclaren",
    path: "/:locale()/shop/limited-edition/mclaren-smoke-renegades",
    meta: {"sku":"renegades-le_mclaren","baseSku":"renegades","id":"69566773","index":true,"title":"SunGod McLaren F1 Team Renegades™ - Smoke","theme":"mclaren","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/mclaren-smoke-renegades"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_giants",
    path: "/:locale()/shop/limited-edition/renegades-le_giants",
    meta: {"sku":"renegades-le_giants","baseSku":"renegades","id":"TAvAPF-lSAesQCd3cFcF-A","index":true,"title":"San Francisco Giants Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/renegades-le_giants"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_ecb",
    path: "/:locale()/shop/limited-edition/ecb-renegades",
    meta: {"sku":"renegades-le_ecb","baseSku":"renegades","id":"G-abbyMvRY24tpHIc6w4TA","index":true,"title":"Limited Edition England Cricket Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ecb-renegades"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_dodgers",
    path: "/:locale()/shop/limited-edition/renegades-le_dodgers",
    meta: {"sku":"renegades-le_dodgers","baseSku":"renegades","id":"c7ybxpIaTGGdsqTS-Arsjg","index":true,"title":"LA Dodgers Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/renegades-le_dodgers"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegades-le_astros",
    path: "/:locale()/shop/limited-edition/renegades-le_astros",
    meta: {"sku":"renegades-le_astros","baseSku":"renegades","id":"edoLzwnzR7aImInmTqNKcQ","index":true,"title":"Houston Astros Renegades™ Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/renegades-le_astros"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-miras-le_ecb",
    path: "/:locale()/shop/limited-edition/ecb-miras",
    meta: {"sku":"miras-le_ecb","baseSku":"miras","id":"eZtsfhqKRoiFSrB3r4ILxg","index":true,"title":"Limited Edition England Cricket Miras™","theme":"sungod","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/ecb-miras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-classics4-le_padrescc",
    path: "/:locale()/shop/limited-edition/classics4-le_padres-cc",
    meta: {"sku":"classics4-le_padrescc","baseSku":"classics3","id":"BB-TFv2zQ0qjQ9CgUYy_Zg","index":true,"title":"SD Padres City Connect Classics³: Official Eyewear Supplier","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/classics4-le_padres-cc"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-classics4-le_padres",
    path: "/:locale()/shop/limited-edition/classics4-le_padres",
    meta: {"sku":"classics4-le_padres","baseSku":"classics3","id":"P2palFF1RuSsB43n8UKDFA","index":true,"title":"San Diego Padres Classics³: Official Eyewear Supplier","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/classics4-le_padres"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-classics4-le_newera",
    path: "/:locale()/shop/limited-edition/new-era-classics4-sunglasses",
    meta: {"sku":"classics4-le_newera","baseSku":"classics3","id":"QGcnJrS1TKu-VX3lRQEkqw","index":true,"title":"Limited Edition SunGod x New Era Classics³ Sunglasses","theme":"gold","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/new-era-classics4-sunglasses"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-classics4-le_courtney",
    path: "/:locale()/shop/limited-edition/courtney-classics",
    meta: {"sku":"classics4-le_courtney","baseSku":"classics3","id":"cdJrCoPOTviClT9fyARxew","index":true,"title":"SunGod Courtney Dauwalter Classics³","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/courtney-classics"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-classics3-le_sas",
    path: "/:locale()/shop/limited-edition/sas-classics3",
    meta: {"sku":"classics3-le_sas","baseSku":"classics3","id":"122678655","index":true,"title":"Surfers Against Sewage Collection - Classics³","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/sas-classics3"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-classics3-le_rfu22",
    path: "/:locale()/shop/limited-edition/england-rugby-classics",
    meta: {"sku":"classics3-le_rfu22","baseSku":"classics3","id":"102317858","index":true,"title":"SunGod x England Rugby Sunglasses -  Classics³ ","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/limited-edition/england-rugby-classics"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-classics3-le_airton",
    path: "/:locale()/shop/signature-series/airton-cozzolino",
    meta: {"sku":"classics3-le_airton","baseSku":"classics3","id":"68051738","index":true,"title":"SunGod Airton Cozzolino Signature Series","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/signature-series/airton-cozzolino"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-miraslrk",
    path: "/:locale()/shop/lenses/miras",
    meta: {"sku":"miraslrk","baseSku":"miras","id":"JjawnbaPSeONY8qWg5Vp0Q","index":true,"title":" SunGod Miras™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/miras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-gtslrk",
    path: "/:locale()/shop/lenses/gts",
    meta: {"sku":"gtslrk","baseSku":"gts","id":"V4Z-I3jDTsKnhTX-4gvGYg","index":true,"title":" SunGod GTs™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/gts"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-tempestslrk",
    path: "/:locale()/shop/lenses/tempests",
    meta: {"sku":"tempestslrk","baseSku":"tempests","id":"121934243","index":true,"title":"SunGod Tempests™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/tempests"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-zephyrslrk",
    path: "/:locale()/shop/lenses/zephyrs",
    meta: {"sku":"zephyrslrk","baseSku":"zephyrs","id":"122331765","index":true,"title":" SunGod Zephyrs™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/zephyrs"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-tokaslrk",
    path: "/:locale()/shop/lenses/tokas",
    meta: {"sku":"tokaslrk","baseSku":"tokas","id":"118429377","index":true,"title":" SunGod Tokas™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/tokas"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sierraslrk",
    path: "/:locale()/shop/lenses/sierras",
    meta: {"sku":"sierraslrk","baseSku":"sierras","id":"63537441","index":true,"title":" SunGod Sierras™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/sierras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-classics3lrk",
    path: "/:locale()/shop/lenses/classics3",
    meta: {"sku":"classics3lrk","baseSku":"classics3","id":"63537437","index":true,"title":"SunGod Classics³ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/classics3"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-renegadeslrk",
    path: "/:locale()/shop/lenses/renegades",
    meta: {"sku":"renegadeslrk","baseSku":"renegades","id":"63537439","index":true,"title":"SunGod Renegades™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/renegades"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-airaslrk",
    path: "/:locale()/shop/lenses/airas",
    meta: {"sku":"airaslrk","baseSku":"airas","id":"63537487","index":true,"title":" SunGod Airas™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/airas"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ultraslrk",
    path: "/:locale()/shop/lenses/ultras",
    meta: {"sku":"ultraslrk","baseSku":"ultraslrk","id":"121140949","index":true,"title":"SunGod Ultras™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/ultras"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vulcanslrk",
    path: "/:locale()/shop/lenses/vulcans",
    meta: {"sku":"vulcanslrk","baseSku":"vulcans","id":"63537402","index":true,"title":" SunGod Vulcans™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/vulcans"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-velanslrk",
    path: "/:locale()/shop/lenses/velans",
    meta: {"sku":"velanslrk","baseSku":"velans","id":"63537430","index":true,"title":" SunGod Velans™ Lens Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/velans"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-egiftcard",
    path: "/:locale()/gifts",
    meta: {"sku":"egiftcard","baseSku":"egiftcard","id":"94211574","index":true,"theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/gifts"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-pacebreakerslrk",
    path: "/:locale()/shop/lenses/pacebreakers",
    meta: {"sku":"pacebreakerslrk","baseSku":"pacebreakers","id":"63537536","index":true,"theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/lenses/pacebreakers"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-airasck",
    path: "/:locale()/shop/parts/airas",
    meta: {"sku":"airasck","baseSku":"airas","id":"63537496","index":true,"title":" SunGod Airas™ Conversion Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/airas"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vulcansck",
    path: "/:locale()/shop/parts/vulcans",
    meta: {"sku":"vulcansck","baseSku":"vulcans","id":"63537393","index":true,"title":" SunGod Vulcans™ Conversion Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/vulcans"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-gogglelensprotector-gc_black",
    path: "/:locale()/shop/accessories/goggle-lens-protector/black",
    meta: {"sku":"gogglelensprotector-gc_black","baseSku":"gogglelensprotector","id":"FVR1fJ-nR0uzjxU74UakqQ","index":true,"title":"Black Goggle Lens Protector","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/goggle-lens-protector/black"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-gogglelensprotector-gc_blue",
    path: "/:locale()/shop/accessories/goggle-lens-protector/blue",
    meta: {"sku":"gogglelensprotector-gc_blue","baseSku":"gogglelensprotector","id":"CjCOw7gARDGfjdv_DxoJJA","index":true,"title":"Blue Goggle Lens Protector","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/goggle-lens-protector/blue"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-gogglelensprotector",
    path: "/:locale()/shop/accessories/goggle-lens-protector",
    meta: {"sku":"gogglelensprotector","baseSku":"gogglelensprotector","id":"bvu6ro2qS4ii-_BEftshuw","index":true,"title":"Goggle Lens Protector","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/goggle-lens-protector"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-microfibre-le_pacex2",
    path: "/:locale()/shop/accessories/pace-microfibre-kit",
    meta: {"sku":"microfibre-le_pacex2","baseSku":"microfibre","id":"212098380","index":true,"title":"Pace Microfibre Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/pace-microfibre-kit"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-microfibre-le_lifestylex2",
    path: "/:locale()/shop/accessories/lifestyle-microfibre-kit",
    meta: {"sku":"microfibre-le_lifestylex2","baseSku":"microfibre","id":"106399845","index":true,"title":"Lifestyle Microfibre Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/accessories/lifestyle-microfibre-kit"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ullrsparts",
    path: "/:locale()/shop/parts/ullrs",
    meta: {"sku":"ullrsparts","baseSku":"ullrs","id":"IdrMUcQjT2WsNl6pLttpag","index":true,"title":" SunGod Ullrs™ Parts Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/ullrs"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-revoltparts",
    path: "/:locale()/shop/parts/revolts",
    meta: {"sku":"revoltparts","baseSku":"revolts","id":"63537459","index":true,"title":" Revolts™ Goggles Part Kits","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/revolts"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-vanguardsparts",
    path: "/:locale()/shop/parts/vanguards",
    meta: {"sku":"vanguardsparts","baseSku":"vanguards","id":"63537443","index":true,"title":" SunGod Vanguards™ Parts Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/vanguards"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-snipersparts",
    path: "/:locale()/shop/parts/snipers",
    meta: {"sku":"snipersparts","baseSku":"snipers","id":"63537450","index":true,"title":" SunGod Snipers™ Parts Kit","theme":"","extendedPage":true,"pageType":"Product Page","unlocalisedPath":"/shop/parts/snipers"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ineos-britannia",
    path: "/:locale()/shop/ineos-britannia",
    meta: {"sku":"tempests-le_ineosbritannia","slug":"ineos-britannia","isComposite":true,"isCompositeRoot":true,"index":true,"title":"INEOS Britannia Sunglasses Collection","theme":"","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/ineos-britannia"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ineos-britannia-tempests-le_ineosbritannia",
    path: "/:locale()/shop/ineos-britannia/tempests-le_ineosbritannia",
    meta: {"sku":"tempests-le_ineosbritannia","slug":"ineos-britannia","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-britannia/tempests-le_ineosbritannia"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ineos-britannia-sierras-le_ineosbritannia",
    path: "/:locale()/shop/ineos-britannia/sierras-le_ineosbritannia",
    meta: {"sku":"sierras-le_ineosbritannia","slug":"ineos-britannia","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-britannia/sierras-le_ineosbritannia"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-surfers-against-sewage",
    path: "/:locale()/shop/surfers-against-sewage",
    meta: {"sku":"renegades-le_sas","slug":"surfers-against-sewage","isComposite":true,"isCompositeRoot":true,"index":true,"title":"Surfers Against Sewage Sunglasses","theme":"","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/surfers-against-sewage"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-surfers-against-sewage-renegades-le_sas",
    path: "/:locale()/shop/surfers-against-sewage/renegades-le_sas",
    meta: {"sku":"renegades-le_sas","slug":"surfers-against-sewage","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/surfers-against-sewage/renegades-le_sas"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-surfers-against-sewage-classics3-le_sas",
    path: "/:locale()/shop/surfers-against-sewage/classics3-le_sas",
    meta: {"sku":"classics3-le_sas","slug":"surfers-against-sewage","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/surfers-against-sewage/classics3-le_sas"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-padres",
    path: "/:locale()/shop/mlb-padres",
    meta: {"sku":"vulcans-le_padrescc","slug":"mlb-padres","isComposite":true,"isCompositeRoot":true,"index":true,"title":"San Diego Padres Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mlb-padres"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-padres-vulcans-le_padrescc",
    path: "/:locale()/shop/mlb-padres/vulcans-le_padrescc",
    meta: {"sku":"vulcans-le_padrescc","slug":"mlb-padres","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-padres/vulcans-le_padrescc"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-padres-classics4-le_padrescc",
    path: "/:locale()/shop/mlb-padres/classics4-le_padrescc",
    meta: {"sku":"classics4-le_padrescc","slug":"mlb-padres","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-padres/classics4-le_padrescc"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-padres-vulcans-le_padres",
    path: "/:locale()/shop/mlb-padres/vulcans-le_padres",
    meta: {"sku":"vulcans-le_padres","slug":"mlb-padres","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-padres/vulcans-le_padres"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-padres-classics4-le_padres",
    path: "/:locale()/shop/mlb-padres/classics4-le_padres",
    meta: {"sku":"classics4-le_padres","slug":"mlb-padres","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-padres/classics4-le_padres"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-padres-zephyrs-le_padres",
    path: "/:locale()/shop/mlb-padres/zephyrs-le_padres",
    meta: {"sku":"zephyrs-le_padres","slug":"mlb-padres","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-padres/zephyrs-le_padres"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-padres-renegades-le_padres",
    path: "/:locale()/shop/mlb-padres/renegades-le_padres",
    meta: {"sku":"renegades-le_padres","slug":"mlb-padres","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-padres/renegades-le_padres"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-astros",
    path: "/:locale()/shop/mlb-astros",
    meta: {"sku":"zephyrs-le_astros","slug":"mlb-astros","isComposite":true,"isCompositeRoot":true,"index":true,"title":"Houston Astros Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mlb-astros"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-astros-zephyrs-le_astros",
    path: "/:locale()/shop/mlb-astros/zephyrs-le_astros",
    meta: {"sku":"zephyrs-le_astros","slug":"mlb-astros","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-astros/zephyrs-le_astros"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-astros-renegades-le_astros",
    path: "/:locale()/shop/mlb-astros/renegades-le_astros",
    meta: {"sku":"renegades-le_astros","slug":"mlb-astros","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-astros/renegades-le_astros"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-giants",
    path: "/:locale()/shop/mlb-giants",
    meta: {"sku":"renegades-le_giants","slug":"mlb-giants","isComposite":true,"isCompositeRoot":true,"index":true,"title":"San Francisco Giants Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mlb-giants"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-giants-renegades-le_giants",
    path: "/:locale()/shop/mlb-giants/renegades-le_giants",
    meta: {"sku":"renegades-le_giants","slug":"mlb-giants","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-giants/renegades-le_giants"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-giants-zephyrs-le_giants",
    path: "/:locale()/shop/mlb-giants/zephyrs-le_giants",
    meta: {"sku":"zephyrs-le_giants","slug":"mlb-giants","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-giants/zephyrs-le_giants"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-dodgers",
    path: "/:locale()/shop/mlb-dodgers",
    meta: {"sku":"renegades-le_dodgers","slug":"mlb-dodgers","isComposite":true,"isCompositeRoot":true,"index":true,"title":"LA Dodgers Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mlb-dodgers"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-dodgers-renegades-le_dodgers",
    path: "/:locale()/shop/mlb-dodgers/renegades-le_dodgers",
    meta: {"sku":"renegades-le_dodgers","slug":"mlb-dodgers","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-dodgers/renegades-le_dodgers"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-dodgers-zephyrs-le_dodgers",
    path: "/:locale()/shop/mlb-dodgers/zephyrs-le_dodgers",
    meta: {"sku":"zephyrs-le_dodgers","slug":"mlb-dodgers","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-dodgers/zephyrs-le_dodgers"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-redsox",
    path: "/:locale()/shop/mlb-redsox",
    meta: {"sku":"renegades-le_redsox","slug":"mlb-redsox","isComposite":true,"isCompositeRoot":true,"index":true,"title":"Boston Red Sox Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mlb-redsox"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-redsox-renegades-le_redsox",
    path: "/:locale()/shop/mlb-redsox/renegades-le_redsox",
    meta: {"sku":"renegades-le_redsox","slug":"mlb-redsox","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-redsox/renegades-le_redsox"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-redsox-zephyrs-le_redsox",
    path: "/:locale()/shop/mlb-redsox/zephyrs-le_redsox",
    meta: {"sku":"zephyrs-le_redsox","slug":"mlb-redsox","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-redsox/zephyrs-le_redsox"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-yankees",
    path: "/:locale()/shop/mlb-yankees",
    meta: {"sku":"renegades-le_yankees","slug":"mlb-yankees","isComposite":true,"isCompositeRoot":true,"index":true,"title":"New York Yankees Sunglasses","theme":"sungod","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mlb-yankees"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-yankees-renegades-le_yankees",
    path: "/:locale()/shop/mlb-yankees/renegades-le_yankees",
    meta: {"sku":"renegades-le_yankees","slug":"mlb-yankees","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-yankees/renegades-le_yankees"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mlb-yankees-zephyrs-le_yankees",
    path: "/:locale()/shop/mlb-yankees/zephyrs-le_yankees",
    meta: {"sku":"zephyrs-le_yankees","slug":"mlb-yankees","isComposite":true,"index":false,"theme":"sungod","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mlb-yankees/zephyrs-le_yankees"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mclaren",
    path: "/:locale()/shop/mclaren",
    meta: {"sku":"sierras-le_mclaren24monaco","slug":"mclaren","isComposite":true,"isCompositeRoot":true,"index":true,"title":"SunGod x McLaren Formula 1 Team Sunglasses","theme":"mclaren","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/mclaren"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mclaren-sierras-le_mclaren24monaco",
    path: "/:locale()/shop/mclaren/sierras-le_mclaren24monaco",
    meta: {"sku":"sierras-le_mclaren24monaco","slug":"mclaren","isComposite":true,"index":false,"theme":"mclaren","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/sierras-le_mclaren24monaco"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mclaren-renegades-le_mclaren24",
    path: "/:locale()/shop/mclaren/renegades-le_mclaren24",
    meta: {"sku":"renegades-le_mclaren24","slug":"mclaren","isComposite":true,"index":false,"theme":"mclaren","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/renegades-le_mclaren24"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mclaren-sierras-le_mclaren24",
    path: "/:locale()/shop/mclaren/sierras-le_mclaren24",
    meta: {"sku":"sierras-le_mclaren24","slug":"mclaren","isComposite":true,"index":false,"theme":"mclaren","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/sierras-le_mclaren24"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mclaren-vulcans-le_mclaren24",
    path: "/:locale()/shop/mclaren/vulcans-le_mclaren24",
    meta: {"sku":"vulcans-le_mclaren24","slug":"mclaren","isComposite":true,"index":false,"theme":"mclaren","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/vulcans-le_mclaren24"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mclaren-zephyrs-le_ln4",
    path: "/:locale()/shop/mclaren/zephyrs-le_ln4",
    meta: {"sku":"zephyrs-le_ln4","slug":"mclaren","isComposite":true,"index":false,"theme":"ln4","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/zephyrs-le_ln4"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mclaren-renegades-le_mclarenmiami23",
    path: "/:locale()/shop/mclaren/renegades-le_mclarenmiami23",
    meta: {"sku":"renegades-le_mclarenmiami23","slug":"mclaren","isComposite":true,"index":false,"theme":"miami","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/renegades-le_mclarenmiami23"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-mclaren-zephyrs-le_mclaren24",
    path: "/:locale()/shop/mclaren/zephyrs-le_mclaren24",
    meta: {"sku":"zephyrs-le_mclaren24","slug":"mclaren","isComposite":true,"index":false,"theme":"mclaren","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/mclaren/zephyrs-le_mclaren24"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ineos-grenadiers",
    path: "/:locale()/shop/ineos-grenadiers",
    meta: {"sku":"vulcans-le_ineosgrenadiers","slug":"ineos-grenadiers","isComposite":true,"isCompositeRoot":true,"index":true,"title":"INEOS Grenadiers Team Edition Sunglasses","theme":"","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/ineos-grenadiers"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ineos-grenadiers-vulcans-le_ineosgrenadiers",
    path: "/:locale()/shop/ineos-grenadiers/vulcans-le_ineosgrenadiers",
    meta: {"sku":"vulcans-le_ineosgrenadiers","slug":"ineos-grenadiers","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-grenadiers/vulcans-le_ineosgrenadiers"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ineos-grenadiers-airas-le_ineosgrenadiers",
    path: "/:locale()/shop/ineos-grenadiers/airas-le_ineosgrenadiers",
    meta: {"sku":"airas-le_ineosgrenadiers","slug":"ineos-grenadiers","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-grenadiers/airas-le_ineosgrenadiers"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ineos-grenadiers-velans-le_ineosgrenadiers",
    path: "/:locale()/shop/ineos-grenadiers/velans-le_ineosgrenadiers",
    meta: {"sku":"velans-le_ineosgrenadiers","slug":"ineos-grenadiers","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-grenadiers/velans-le_ineosgrenadiers"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ineos-grenadiers-velans-le_tompidcock",
    path: "/:locale()/shop/ineos-grenadiers/velans-le_tompidcock",
    meta: {"sku":"velans-le_tompidcock","slug":"ineos-grenadiers","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-grenadiers/velans-le_tompidcock"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-ineos-grenadiers-velans-vef_Iwhite-vele_8silverblue-vei_Bgold-vees_Rwhite-ven_Iwhite",
    path: "/:locale()/shop/ineos-grenadiers/velans-vef_Iwhite-vele_8silverblue-vei_Bgold-vees_Rwhite-ven_Iwhite",
    meta: {"sku":"velans-vef_Iwhite-vele_8silverblue-vei_Bgold-vees_Rwhite-ven_Iwhite","slug":"ineos-grenadiers","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/ineos-grenadiers/velans-vef_Iwhite-vele_8silverblue-vei_Bgold-vees_Rwhite-ven_Iwhite"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sailgp-gbr",
    path: "/:locale()/shop/sailgp-gbr",
    meta: {"sku":"tempests-le_sailgp","slug":"sailgp-gbr","isComposite":true,"isCompositeRoot":true,"index":true,"title":"SunGod x Great Britain SailGP Team Sunglasses","theme":"","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/sailgp-gbr"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-sailgp-gbr-tempests-le_sailgp",
    path: "/:locale()/shop/sailgp-gbr/tempests-le_sailgp",
    meta: {"sku":"tempests-le_sailgp","slug":"sailgp-gbr","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/sailgp-gbr/tempests-le_sailgp"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-momentum",
    path: "/:locale()/shop/momentum",
    meta: {"sku":"vulcans-le_momentum","slug":"momentum","isComposite":true,"isCompositeRoot":true,"index":true,"title":"SunGod Momentum Vulcans™ & Velans™ Sunglasses","theme":"","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/momentum"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-momentum-vulcans-le_momentum",
    path: "/:locale()/shop/momentum/vulcans-le_momentum",
    meta: {"sku":"vulcans-le_momentum","slug":"momentum","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/momentum/vulcans-le_momentum"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "pdp-momentum-velans-le_momentum",
    path: "/:locale()/shop/momentum/velans-le_momentum",
    meta: {"sku":"velans-le_momentum","slug":"momentum","isComposite":true,"index":false,"theme":"","extendedPage":true,"pageType":"Product Detail Page","unlocalisedPath":"/shop/momentum/velans-le_momentum"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/[product]/index.vue")
  },
  {
    name: "typeform-run-with-courtney",
    path: "/:locale()/run-with-courtney",
    meta: {"slug":"run-with-courtney","index":false,"formId":"w53w80ds","extendedPage":true,"pageType":"Form Page","unlocalisedPath":"/run-with-courtney"},
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue")
  },
  {
    name: "typeform-co-brand-form",
    path: "/:locale()/co-brand-form",
    meta: {"slug":"co-brand-form","index":false,"formId":"bbMbHzrB","extendedPage":true,"pageType":"Form Page","unlocalisedPath":"/co-brand-form"},
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue")
  },
  {
    name: "typeform-prescription-lenses",
    path: "/:locale()/prescription-lenses",
    meta: {"slug":"prescription-lenses","index":false,"formId":"hzs484kM","extendedPage":true,"pageType":"Form Page","unlocalisedPath":"/prescription-lenses"},
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue")
  },
  {
    name: "typeform-fwq-fjt-athletes",
    path: "/:locale()/fwq-fjt-athletes",
    meta: {"slug":"fwq-fjt-athletes","index":false,"formId":"eMKDck","extendedPage":true,"pageType":"Form Page","unlocalisedPath":"/fwq-fjt-athletes"},
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue")
  },
  {
    name: "typeform-pro-deal",
    path: "/:locale()/pro-deal",
    meta: {"slug":"pro-deal","index":false,"formId":"rxXr5A","extendedPage":true,"pageType":"Form Page","unlocalisedPath":"/pro-deal"},
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue")
  },
  {
    name: "typeform-return-authorisation",
    path: "/:locale()/return-authorisation",
    meta: {"slug":"return-authorisation","index":false,"formId":"p2nSC6","extendedPage":true,"pageType":"Form Page","unlocalisedPath":"/return-authorisation"},
    component: () => import("/vercel/path0/pages/[locale]/typeform/[slug].vue")
  },
  {
    name: "ec-running",
    path: "/:locale()/explore/running",
    meta: {"categoryId":"XMSuBQddQTmHPFNY3AMtIA","slug":"running","index":true,"theme":"explore","title":"Running | SunGod. See Better.","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/running"},
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue")
  },
  {
    name: "ec-explore-running",
    path: "/:locale()/explore/explore-running",
    meta: {"categoryId":"OJqa8jUoSVGt_-WHJECpDg","slug":"explore-running","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/explore-running"},
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue")
  },
  {
    name: "ec-product-guides",
    path: "/:locale()/explore/product-guides",
    meta: {"categoryId":"SynDO1jpQcWvRJO8RvO5RA","slug":"product-guides","index":true,"theme":"explore","title":"Product Guides ","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/product-guides"},
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue")
  },
  {
    name: "ec-sports",
    path: "/:locale()/explore/sports",
    meta: {"categoryId":"W1G_AGtoQ2WXa5xgr8lfVQ","slug":"sports","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/sports"},
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue")
  },
  {
    name: "ec-cycling",
    path: "/:locale()/explore/cycling",
    meta: {"categoryId":"fg9D88DoRIWBX9oexQZzxA","slug":"cycling","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/cycling"},
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue")
  },
  {
    name: "ec-snowsports",
    path: "/:locale()/explore/snowsports",
    meta: {"categoryId":"DnvidYYgRGaOxFxzt-DquQ","slug":"snowsports","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/snowsports"},
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue")
  },
  {
    name: "ec-athlete-stories",
    path: "/:locale()/explore/athlete-stories",
    meta: {"categoryId":"MywN3iPMSiyplVGFs5sW4A","slug":"athlete-stories","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/athlete-stories"},
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue")
  },
  {
    name: "ec-pro-team",
    path: "/:locale()/explore/pro-team",
    meta: {"categoryId":"80143887","slug":"pro-team","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/pro-team"},
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue")
  },
  {
    name: "ec-video",
    path: "/:locale()/explore/video",
    meta: {"categoryId":"80143904","slug":"video","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/video"},
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue")
  },
  {
    name: "ec-stories",
    path: "/:locale()/explore/stories",
    meta: {"categoryId":"80143878","slug":"stories","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/stories"},
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue")
  },
  {
    name: "ec-news",
    path: "/:locale()/explore/news",
    meta: {"categoryId":"80143880","slug":"news","index":true,"theme":"explore","extendedPage":true,"pageType":"Explore Category","unlocalisedPath":"/explore/news"},
    component: () => import("/vercel/path0/pages/[locale]/explore/[category]/index.vue")
  },
  {
    name: "legacy-collection-shop__lenses",
    path: "/:locale()/shop/lenses",
    meta: {"index":true,"title":"Replacement Lenses & Parts","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/shop/lenses"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/index.vue")
  },
  {
    name: "legacy-collection-shop__parts",
    path: "/:locale()/shop/parts",
    meta: {"index":true,"title":"Goggle Parts","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/shop/parts"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/index.vue")
  },
  {
    name: "legacy-collection-shop__cases",
    path: "/:locale()/shop/cases",
    meta: {"index":true,"title":"Cases","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/shop/cases"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/index.vue")
  },
  {
    name: "legacy-collection-shop__parts__pace",
    path: "/:locale()/shop/parts/pace",
    meta: {"index":true,"title":"Pace Series™ Parts","extendedPage":true,"pageType":"Product Landing Page","unlocalisedPath":"/shop/parts/pace"},
    component: () => import("/vercel/path0/pages/[locale]/shop/[category]/index.vue")
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/*/*/cart",
    component: component_45stub5X4Ei38PMg
  }
]